import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import CardFlip from "./CardFlip";
import SliderWeek7 from "./SliderWeek7";
import SliderWeek7_1 from "./SliderWeek7_1";
import Collapse from "../components/Collapse";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";
import Confetti from "react-confetti";

const Week8 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 8;
  const week_name = "week_7";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [checkBox, setCheckBox] = useState(false);
  const [RecheckBox, setRecheckBox] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    response: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            response: data.response,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setSubmission({
            response: data.response,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config,
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 6, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 6);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      let new_step = currentStep + 1;
      // Reset states when changing steps
      setIsOptionSelected(false);
      if (new_step === 2) {
        setIsSubmitted(false);
        setDisableBtn(false);
      }
      if (new_step > section) {
        let new_status = "Active";
        if (new_step === totalSteps) {
          new_status = "Completed";
        }
        axios
          .put(
            `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
            {
              user_id: user.user_id,
              section: new_step,
              week_name: week_name,
              week_id: week_id,
              status: new_status,
              pathway_id,
              next_week_name: "week_8",
            },
            config,
          )
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setCurrentStep(new_step);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const [isSubmitted, setIsSubmitted] = useState(false);

  function countWords(text) {
    if (!text) return 0;
    const trimmedText = text.trim();
    if (trimmedText === '') return 0;
    const words = trimmedText.split(/\s+/);
    return words.length;
  }

  const handleStepSubmission = () => {
    console.log("this step", currentStep);

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
        {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: { reflection: swotSubmission.response },
          pathway: 1,
          status: "Reviewing",
          pr_enabled: true,
          rating: 0,
          activity_name: "Argument Analysis in Student-Run Organizations"
        },
        config
      )
      .then((response) => {
        console.log("Submission successful:", response);
        setIsSubmitted(true);
        setDisableBtn(true);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        console.error("Error submitting activity:", error);
        toast.error("Oops! Something went wrong. Please try again later.", {
          icon: "❌",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  useEffect(() => {
    console.log("isSubmitted state:", isSubmitted);
  }, [isSubmitted]);

  useEffect(() => {
    console.log("Option selected state:", isOptionSelected);
  }, [isOptionSelected]);

  const totalSteps = 6; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const questionsData = [
    {
      title: "Ethical Dimensions of Critical Thinking ",
      question:
        "Considering multiple perspectives in decision-making is unnecessary for critical thinking in student societies.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },

    {
      title: "Ethical Dimensions of Critical Thinking ",
      question:
        "In critical thinking, ethical considerations are as important as logical reasoning in making decision",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },

    {
      title: "Ethical Dimensions of Critical Thinking ",
      question:
        "- It is acceptable to ignore ethical implications if the decision is logically sound and benefits the majority in a student society.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },

    {
      title: "Ethical Dimensions of Critical Thinking ",
      question:
        "In critical thinking, questioning the ethical impact of a decision can lead to better and more inclusive outcomes.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },

    {
      title: "Ethical Dimensions of Critical Thinking ",
      question:
        "Ethical dilemmas are irrelevant to the process of critical thinking in leadership roles within student societies.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },

    {
      title: "The Conference Challenge",
      question:
        "How do you schedule each speaker on each day, adhering to their constraints?",
      options: [
        "Friday: Speaker A (Economist), Saturday: Speaker B (Social Scientist), Sunday: Speaker C (Environmental Studies)",
        "Friday: Speaker B (Social Scientist), Saturday: Speaker A (Economist), Sunday: Speaker C (Environmental Studies)",
        "Friday: Speaker C (Environmental Studies), Saturday: Speaker A (Economist), Sunday: Speaker B (Social Scientist)",
        "Friday: Speaker C (Environmental Studies), Saturday: Speaker B (Social Scientist), Sunday: Speaker A (Economist)",
      ],
      correctAnswer:
        "Friday: Speaker B (Social Scientist), Saturday: Speaker A (Economist), Sunday: Speaker C (Environmental Studies)",
    },

    {
      title: "The Fundraiser Allocation",
      question:
        "How do you allocate the funds among the four initiatives, respecting these conditions? (Let's assume the total funds raised are 120 units)",
      options: [
        "Educational Campaign: 20 units, Community Service Project: 30 units, Cultural Event: 40 units, Operational Costs: 30 units",
        "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
        "Educational Campaign: 25 units, Community Service Project: 20 units, Cultural Event: 35 units, Operational Costs: 40 units",
        "Educational Campaign: 30 units, Community Service Project: 25 units, Cultural Event: 25 units, Operational Costs: 40 units",
      ],
      correctAnswer:
        "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
    },

    {
      title: "The Fundraiser Allocation",
      question:
        "How do you allocate the funds among the four initiatives, respecting these conditions? (Let's assume the total funds raised are 120 units)",
      options: [
        "Educational Campaign: 20 units, Community Service Project: 30 units, Cultural Event: 40 units, Operational Costs: 30 units",
        "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
        "Educational Campaign: 25 units, Community Service Project: 20 units, Cultural Event: 35 units, Operational Costs: 40 units",
        "Educational Campaign: 30 units, Community Service Project: 25 units, Cultural Event: 25 units, Operational Costs: 40 units",
      ],
      correctAnswer:
        "Educational Campaign: 40 units, Community Service Project: 15 units, Cultural Event: 30 units, Operational Costs: 20 units",
      explanation:
        "If the educational campaign gets twice as much as operational costs, and the cultural event gets a maximum of one-third of the total (which is 40 units), the only way to satisfy all conditions is to give the educational campaign 40 units (2 times the operational costs). The community service project gets half of the cultural event, which is 15 units. This leaves 20 units for the operational costs (half of the educational campaign). The total allocation adds up to 120 units, satisfying all conditions and constraints.",
    },

    {
      title: "The Age Puzzle",
      question:
        "In a room sit three people: a father, a son, and a grandfather. The total number of years of their ages equals 140 years. The grandfather is twice as old as the father, and the father is twice as old as the son. How old is each person?",
      options: [
        "The son is 25 years old, the father is 50, and the grandfather is 65.",
        "The son is 18 years old, the father is 36, and the grandfather is 72.",
        "The son is 20 years old, the father is 40, and the grandfather is 80.",
        "The son is 15 years old, the father is 30, and the grandfather is 60.",
      ],
      correctAnswer:
        "The son is 20 years old, the father is 40, and the grandfather is 80.",
    },

    {
      title: "Survivor Puzzle",
      question:
        "If a plane crashes on the border between Pakistan and India, where do they bury the survivors?",
      options: [
        "On the Pakistani side.",
        "On the Indian side.",
        "Nowhere, because you don't bury survivors.",
        "In a neutral third country.",
      ],
      correctAnswer: "Nowhere, because you don't bury survivors.",
    },

    {
      title: "The Scrambled Word Puzzle",
      question:
        "What five-letter word becomes shorter when you add two letters to it?",
      options: ["Length", "Short", "Width", "Bread"],
      correctAnswer: "Short",
    },

    {
      title: "The Farmer's Dilemma",
      question: "A farmer has 17 sheep, and all but 9 die. How many are left?",
      options: [
        "0 sheep are left.",
        "8 sheep are left.",
        "9 sheep are left.",
        "17 sheep are left.",
      ],
      correctAnswer: "9 sheep are left.",
      explanation:
        "The phrase 'all but 9 die' means every sheep except for 9 died, so 9 sheep remain.",
    },
  ];

  const accordionData = [
    {
      title: "Fostering Inclusive Debate",
      content: (
        <div>
          <strong>Problem:</strong> Malaika notices varying opinions within
          LUMUN regarding debate topics and strategies.
          <br />
          <strong>Malaika’s Approach:</strong> She encourages open and critical
          discussions, allowing members to analyze and challenge each argument.
          This approach promotes intellectual diversity and strengthens the
          society's analytical capabilities.
        </div>
      ),
    },
    {
      title: "Navigating Unforeseen Changes",
      content: (
        <div>
          <strong>Problem:</strong> A sudden change in a major event's schedule
          puts Malaika and her team in a challenging position.
          <br />
          <strong>Malaika’s Approach:</strong> She leverages this as an
          opportunity to demonstrate adaptability and swift, critical
          decision-making. Together with her team, she re-evaluates their
          strategy and develops a revised plan, showcasing the power of critical
          thinking in crisis management.
        </div>
      ),
    },
    {
      title: "Utilizing Team Potential",
      content: (
        <div>
          <strong>Problem:</strong> The LUMUN team comprises debaters with
          varying strengths and skill sets.
          <br />
          <strong>Malaika’s Approach:</strong> Understanding and leveraging
          these diverse talents, Malaika assigns roles that maximize each
          member's potential, displaying strategic critical thinking in team
          management.
        </div>
      ),
    },
    {
      title: "Balancing Rationality and Sensitivity",
      content: (
        <div>
          <strong>Problem:</strong> High-stress debates can lead to emotionally
          intense situations.
          <br />
          <strong>Malaika’s Approach:</strong> Malaika learns to blend rational
          argumentation with emotional intelligence, ensuring debates remain
          both intellectually rigorous and empathetically conducted.
        </div>
      ),
    },
  ];

  const accordionData3 = [
    {
      title: "Observation and Analysis",
      content: "Cultivating a Keen Eye for Detail and Logical Analysis\n\n",
    },
    {
      title: "Interpretation and Evaluation",
      content:
        "Deciphering Meaning and Assessing Validity\n\n" +
        "Interpreting information accurately and evaluating its validity are critical in making sound decisions. Malaika's ability to interpret arguments in debates and evaluate their strength plays a key role in her leadership at LUMUN.\n\n" +
        "Think about how you interpret information and its implications. Are you drawing conclusions based on solid evidence?\n" +
        "Evaluate the validity and relevance of information you encounter. How does this evaluation impact your decision-making process?\n" +
        "Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader and a team member.\n\n",
    },
    {
      title: "Inference and Explanation",
      content:
        "Drawing Conclusions and Articulating Reasoning\n\n" +
        "Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika's skill in drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading her team.\n\n" +
        "Consider how you draw inferences from information and situations. Are your conclusions based on logical reasoning?\n" +
        "Reflect on your ability to explain your reasoning to others. How does this clarity of thought and expression aid your leadership?\n" +
        "Explore how developing stronger inference and explanation skills can enhance your leadership and problem-solving abilities.\n\n",
    },
    {
      title: "Problem-Solving and Decision-Making",
      content:
        "Navigating Complex Challenges with Reason and Insight\n\n" +
        "Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika's approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical solutions.\n\n" +
        "Analyse your approach to problem-solving. How do you use critical thinking to find innovative solutions?\n" +
        "Reflect on your decision-making process. Are you considering all aspects and potential consequences logically and thoroughly?\n" +
        "Consider how enhancing your problem-solving and decision-making skills through critical thinking can lead to better outcomes for your team and your leadership journey.\n\n",
    },
  ];

  // const accordionData2 = [
  //   {
  //     title: 'Observation and Analysis',
  //     sub: 'Cultivating a Keen Eye for Detail and Logical Analysis',
  //     content:
  //       'Interpretation and Evaluation\n' +
  //       'Deciphering Meaning and Assessing Validity\n\n' +
  //       'Interpreting information accurately and evaluating its validity are critical in making sound decisions. Malaika\'s ability to interpret arguments in debates and evaluate their strength plays a key role in her leadership at LUMUN.\n\n' +
  //       'Think about how you interpret information and its implications. Are you drawing conclusions based on solid evidence?\n' +
  //       'Evaluate the validity and relevance of information you encounter. How does this evaluation impact your decision-making process?\n' +
  //       'Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader and a team member.\n\n',
  //   },
  //   {
  //     title: 'Inference and Explanation',
  //     sub: 'Drawing Conclusions and Articulating Reasoning',
  //     content:
  //       'Inference and Explanation\n' +
  //       'Drawing Conclusions and Articulating Reasoning\n\n' +
  //       'Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika\'s skill in drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading her team.\n\n' +
  //       'Consider how you draw inferences from information and situations. Are your conclusions based on logical reasoning?\n' +
  //       'Reflect on your ability to explain your reasoning to others. How does this clarity of thought and expression aid your leadership?\n' +
  //       'Explore how developing stronger inference and explanation skills can enhance your leadership and problem-solving abilities.\n\n',
  //   },
  //   {
  //     title: 'Problem-Solving and Decision-Making',
  //     sub: 'Navigating Complex Challenges with Reason and Insight',
  //     content:
  //       'Problem-Solving and Decision-Making\n' +
  //       'Navigating Complex Challenges with Reason and Insight\n\n' +
  //       'Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika\'s approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical solutions.\n\n' +
  //       'Analyse your approach to problem-solving. How do you use critical thinking to find innovative solutions?\n' +
  //       'Reflect on your decision-making process. Are you considering all aspects and potential consequences logically and thoroughly?\n' +
  //       'Consider how enhancing your problem-solving and decision-making skills through critical thinking can lead to better outcomes for your team and your leadership journey.\n\n',
  //   },
  // ];

  const accordionData2 = [
    {
      title: "Observation and Analysis",
      sub: "Cultivating a Keen Eye for Detail and Logical Analysis",
      content:
        "Explore the following elements of critical thinking:\n" +
        "Observing details and analysing information critically forms the core of critical thinking. By cultivating\n" +
        "these skills, Malaika learns to discern crucial details and patterns in debates and team discussions,\n" +
        "enhancing her leadership effectiveness.\n" +
        "- Reflect on how you observe and interpret information. Are you considering all relevant details and\n" +
        "perspectives?\n" +
        "- Analyse situations and information critically. How do these insights inform your leadership decisions\n" +
        "and strategies?\n" +
        "- Consider how your observation and analysis skills can be applied to understand complex issues and\n" +
        "lead your team more effectively.\n\n",
    },
    {
      title: "Interpretation and Evaluation",
      sub: "Deciphering Meaning and Assessing Validity",
      content:
        "Interpreting information accurately and evaluating its validity are critical in making sound decisions.\n" +
        "Malaika's ability to interpret arguments in debates and evaluate their strength plays a key role in her\n" +
        "leadership at LUMUN.\n" +
        "- Think about how you interpret information and its implications. Are you drawing conclusions based\n" +
        "on solid evidence?\n" +
        "- Evaluate the validity and relevance of information you encounter. How does this evaluation impact\n" +
        "your decision-making process?\n" +
        "- Reflect on how your interpretation and evaluation skills contribute to your effectiveness as a leader\n" +
        "and a team member.\n\n",
    },
    {
      title: "Inference and Explanation",
      sub: "Drawing Conclusions and Articulating Reasoning",
      content:
        "Making inferences and explaining reasoning are vital aspects of critical thinking. Malaika's skill in\n" +
        "drawing logical conclusions from debates and clearly explaining her reasoning is essential for leading\n" +
        "her team.\n" +
        "- Consider how you draw inferences from information and situations. Are your conclusions based on\n" +
        "logical reasoning?\n" +
        "- Reflect on your ability to explain your reasoning to others. How does this clarity of thought and\n" +
        "expression aid your leadership?\n" +
        "- Explore how developing stronger inference and explanation skills can enhance your leadership and\n" +
        "problem-solving abilities.\n\n",
    },
    {
      title: "Problem-Solving and Decision-Making",
      sub: "Navigating Complex Challenges with Reason and Insight",
      content:
        "Effective problem-solving and decision-making are the hallmarks of a critical thinker. Malaika's\n" +
        "approach to tackling issues within LUMUN showcases her ability to apply critical thinking for practical\n" +
        "solutions.\n" +
        "- Analyse your approach to problem-solving. How do you use critical thinking to find innovative\n" +
        "solutions?\n" +
        "- Reflect on your decision-making process. Are you considering all aspects and potential consequences\n" +
        "logically and thoroughly?\n" +
        "- Consider how enhancing your problem-solving and decision-making skills through critical thinking\n" +
        "can lead to better outcomes for your team and your leadership journey.\n\n",
    },
  ];

  const accordionData4 = [
    {
      title: "Effective Communication",
      content:
        "This week, we delved into the essence of effective communication and its pivotal role in impactful leadership. We studied how clarity, empathy, active listening, and non-verbal cues combine to create powerful communication channels, and we learned this through Preetam's continued leadership journey.\n\n" +
        "Drivers of Effective Communication\n" +
        "We explored the elements of effective communication, including the importance of giving and receiving meaningful messages, the power of non-verbal communication, and the need for adaptable communication styles. Recognizing and understanding these elements are vital for building strong communication skills.\n\n" +
        "Importance of Meaning in Communication\n" +
        "We dove into why it's crucial to infuse our communications with meaning and purpose. We explored this through a narrative that showed how Preetam learned to craft meaningful messages that resonated with his audience and made him a more effective leader.\n\n" +
        "Virtual Public Speaking Exercise\n" +
        "You prepared speeches on provided topics, recorded them, and submitted your videos for peer review. This exercise not only gave you a platform to practice your public speaking skills but also provided you with constructive feedback from your peers.\n\n",
    },
    {
      title: "Fostering Inclusive Debate",
      content:
        "Problem: Malaika notices varying opinions within LUMUN regarding debate topics and strategies.\n" +
        "Malaika’s Approach: She encourages open and critical discussions, allowing members to analyze and challenge each argument. This approach promotes intellectual diversity and strengthens the society's analytical capabilities.\n\n",
    },
    {
      title: "Navigating Unforeseen Changes",
      content:
        "Problem: A sudden change in a major event's schedule puts Malaika and her team in a challenging position.\n" +
        "Malaika’s Approach: She leverages this as an opportunity to demonstrate adaptability and swift, critical decision-making. Together with her team, she re-evaluates their strategy and develops a revised plan, showcasing the power of critical thinking in crisis management.\n\n",
    },
    {
      title: "Utilizing Team Potential",
      content:
        "Problem: The LUMUN team comprises debaters with varying strengths and skill sets.\n" +
        "Malaika’s Approach: Understanding and leveraging these diverse talents, Malaika assigns roles that maximize each member's potential, displaying strategic critical thinking in team management.\n\n",
    },
    {
      title: "Balancing Rationality and Sensitivity",
      content:
        "Problem: High-stress debates can lead to emotionally intense situations.\n" +
        "Malaika’s Approach: Malaika learns to blend rational argumentation with emotional intelligence, ensuring debates remain both intellectually rigorous and empathetically conducted.\n\n",
    },
  ];

  const cardData = [
    {
      id: 1,
      frontContent: "Promote an Environment of Open Inquiry",
      backContent: (
        <div>
          <p className="font-semibold">
            Creating a culture of open inquiry is essential for nurturing
            critical thinking within a group.
          </p>

          <p className="my-2"> Reflect on your recent group interactions: </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              How have I encouraged open and critical discussions among team
              members?
            </li>
            <li>
              In what ways have I facilitated an environment where every opinion
              is valued and challenged constructively?
            </li>
            <li>
              In what ways have I facilitated an environment where every opinion
              is valued and challenged constructively?
            </li>
          </ul>
        </div>
      ),
    },

    {
      id: 2,
      frontContent: "Analyse Group Decision-Making Processes",
      backContent: (
        <div>
          <p className="font-semibold">
            Critical thinking is not just an individual effort but a collective
            one.
          </p>
          <p className="my-2"> Examine your group's decision making: </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              How do we approach problem-solving and decision-making as a team?
              Is it thorough and logical?
            </li>
            <li>
              Where can we improve in ensuring that our decisions are based on
              sound reasoning and not just majority opinion?
            </li>
            <li>
              How can I lead the team to consider various perspectives and
              evidence before reaching conclusions?
            </li>
          </ul>
        </div>
      ),
    },

    {
      id: 3,
      frontContent: "Encourage Diversity of Thought and Counteract Groupthink",
      backContent: (
        <div>
          <p className="font-semibold">
            Diversity of thought is the antidote to groupthink.
          </p>
          <p className="my-2">
            {" "}
            Assess your role in promoting diverse perspectives:{" "}
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Have I been proactive in seeking and respecting different
              viewpoints in the group?
            </li>
            <li>
              How can I better identify and mitigate the risks of groupthink in
              our team discussions and decisions?
            </li>
            <li>
              In what ways can I empower each member to contribute their unique
              insights and challenge the status quo?{" "}
            </li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  const [allFieldsHaveMinimumWords, setallFieldsHaveMinimumWords] =
    useState(false);

  useEffect(() => {
    // const wordCounts = Object.values(swotSubmission).map(value => value.split(/\s+/).filter(word => word !== '').length);
    const wordCounts = Object.values(swotSubmission).map(
      (value) => value.split(/\s+/).filter((word) => word !== "").length,
    );

    if (wordCounts.every((count) => count >= 50)) {
      console.warn("W6 words OK: " + wordCounts);
      setallFieldsHaveMinimumWords(true);
    } else {
      console.warn("W6 words ERROR: " + wordCounts);
      setallFieldsHaveMinimumWords(false);
    }

    // Check if all fields have at least 50 words each
    // const allFieldsHaveMinimumWords = wordCounts.every(count => count >= 50);

    // alert("WORDS: "+allFieldsHaveMinimumWords);

    if (
      currentStep === 0 ||
      currentStep === 1 ||
      currentStep === 2 ||
      currentStep === 3 ||
      currentStep === 4 ||
      currentStep === 5 ||
      currentStep === 7
    ) {
      setNextBtnCondtion(true);
    } else if (currentStep === 6 && status === "Completed") {
      setNextBtnCondtion(true);
    } else if (
      currentStep === 6 &&
      status === "Active" &&
      allFieldsHaveMinimumWords &&
      disableBtn
    ) {
      setNextBtnCondtion(true);
    } else {
      setNextBtnCondtion(false);
    }
  }, [
    currentStep,
    swotSubmission,
    allFieldsHaveMinimumWords,
    status,
    disableBtn,
  ]);

  const questionsData1 = [
    {
      question:
        "You’re organizing a charity bake sale. When should you start promoting it on social media?",
      options: [
        "One week before the event.",
        "Three days before the event.",
        "On the day of the event",
      ],
      correctAnswer: "Three days before the event.",
      explanation:
        "Perfect! Three days is ideal for building excitement without oversaturating your audience.",
    },

    {
      question:
        "Your society is hosting a Movie Night. Which content format will likely generate the most engagement?",
      options: [
        "A static image post of the event poster.",
        "A short Instagram Reel with movie clips and trending audio.",
        "A long video featuring a detailed event description.",
      ],
      correctAnswer:
        "A short Instagram Reel with movie clips and trending audio.",
      explanation:
        "Great choice! Reels are highly favored by Instagram’s algorithm and are more engaging for viewers.",
    },

    {
      question:
        "Your society is promoting a Debate Competition. Which Instagram Story feature will engage your audience the most?",
      options: [
        "A countdown sticker with the competition date.",
        "A plain text Story with event details.",
        "A blurry behind-the-scenes photo.",
      ],
      correctAnswer: "A countdown sticker with the competition date.",
      explanation:
        "Well done! Countdown stickers create urgency and anticipation, encouraging followers to participate.",
    },

    {
      question:
        "Your society’s analytics show that most followers are online between 6 PM and 8 PM. When should you schedule your post?",
      options: ["12 PM (Noon).", "6:30 PM.", "9 PM."],
      correctAnswer: "6:30 PM.",
      explanation:
        "Excellent! Posting during peak activity ensures better reach and engagement.",
    },

    {
      question:
        "Your society is hosting a Talent Hunt. Which hashtag strategy is the best?",
      options: [
        "Use generic hashtags like #fun #event #talent.",
        "Use only your society’s branded hashtag (e.g., #YourSocietyName).",
        "Combine your society’s branded hashtag with trending and event-specific ones (e.g., #TalentHunt2024 #YourSocietyName).",
      ],
      correctAnswer:
        "Combine your society’s branded hashtag with trending and event-specific ones (e.g., #TalentHunt2024 #YourSocietyName).",
      explanation:
        "Perfect! Combining branded, trending, and event-specific hashtags maximizes visibility and engagement.",
    },
  ];

  const questionsData2 = [
    {
      question:
        "Public relations is primarily about maintaining external relationships, and internal relationships are not as important.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Correct! Both internal and external relationships are essential to effective public relations.",
    },

    {
      question:
        "Key messages for event promotion should always be short, clear, and engaging to capture your audience’s attention.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Great job! Clear and concise key messages are vital for making your event promotion impactful.",
    },

    {
      question:
        "When a crisis occurs, it’s best to wait until you have all the information before communicating with your audience.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Correct! Timely communication helps control the narrative and builds trust, even while gathering more information.",
    },

    {
      question:
        "Collaborating with campus media or sponsors can help amplify the reach of your society’s events.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Excellent! Partnering with campus media or sponsors is a smart way to increase visibility and promote your events effectively.",
    },

    {
      question:
        "In a PR crisis, the three key steps are: Ignore the problem, control the narrative, and move on without providing solutions.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Great! Acknowledge, contain, and resolve are the correct steps to effectively handle a PR crisis.",
    },
  ];

  const steps = [
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 8: Marketing and Public Relations for Societies
      </h2>
      <p className="mt-4 text-gray-600">
        Marketing is key to making your society stand out. This week, we’ll
        explore the fundamentals of promoting your society and its events. From
        crafting engaging social media campaigns to building a strong brand
        identity, you’ll learn how to capture attention and grow your presence
        within the LUMS community and beyond.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Content with strong visuals is 40 times more likely to get shared on social media, driving engagement and visibility."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                className="w-6/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1737445367/pana_b2fn0v.png"
              />
            </div>
          </div>
        </div>
      </div>
     </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 1:</b> Basics of Social Media Marketing
      </h2>
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek7
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      <h2 className="text-xl font-semibold sm:text-xl">
        Scenario: The Talent Hunt Buzz
      </h2>
      <p className="mt-4 text-gray-600">
        Your society is hosting <strong>“Talent Hunt 2024”</strong>, and the
        goal is to make it the talk of the campus. As the social media
        coordinator, you’ll guide your team through a comprehensive plan to
        build anticipation, engage followers, and ensure live event interaction.
        Here's how your team approaches the challenge:
      </p>
      <h3 className="mt-6 text-lg font-semibold">Brainstorming the Strategy</h3>
      <p className="text-gray-600">
        The team gathers in the campus café with laptops, sticky notes, and
        markers. You kick things off, saying, “We need this event to trend!”
        Maria scribbles ideas on the whiteboard:{" "}
        <em>“Countdown stickers,” “Behind-the-scenes posts,”</em> and{" "}
        <em>“Polls for live engagement.”</em> The team agrees to launch the
        campaign three days before the event to maximize impact.
      </p>
      <h3 className="mt-6 text-lg font-semibold">
        Posting a Countdown Sticker
      </h3>
      <p className="text-gray-600">
        On day one, you create an Instagram Story featuring a vibrant poster
        with the event’s name and a countdown sticker:
        <strong>“Talent Hunt 2024 – 3 Days to Go!”</strong> The team ensures the
        design is eye-catching and matches the society’s branding.
      </p>
      <h3 className="mt-6 text-lg font-semibold">
        Sharing Behind-the-Scenes Content
      </h3>
      <p className="text-gray-600">
        As the event approaches, you post candid shots of the setup: stacks of
        flyers, a team member testing the stage lights, and a group practicing
        their opening speech. You caption one post,
        <strong>“Getting ready for Talent Hunt 2024! 🎤✨”</strong> with a
        sneak-peek emoji to spark curiosity.
      </p>
      <h3 className="mt-6 text-lg font-semibold">Event Day Engagement</h3>
      <p className="text-gray-600">
        During the event, you post real-time updates on Instagram Stories. One
        includes a poll:
        <strong>“What’s your favorite performance so far?”</strong> Another
        showcases a video of the crowd cheering for a participant. The audience
        is thrilled to see their votes and shoutouts featured live.
      </p>
      <p className="mt-6 text-gray-600">
        Through strategic planning, creative engagement, and active interaction,
        your society not only trends but also leaves a lasting impression on the
        audience.
      </p>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> MCQ Quiz
      </h2>

      <QuizComponent questionsData={questionsData1} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}  />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 3:</b> Reflection Prompt
      </h2>

      <h2 class="text-lg font-bold">Instructions:</h2>
      <p class="mt-2">
        Read the below prompt carefully and take a few minutes to think about
        your answer before responding in the Discussion Forum. Your answer
        should at least be 150 words long.
      </p>
      <h3 class="mt-4 text-lg font-bold">Prompt:</h3>
      <p class="mt-2">
        Think of the social media pages of different societies at LUMS that you
        follow. Pick a society whose Instagram page appeals to you most. What is
        it about their online branding that allows them to have an impactful
        presence in your mind?
      </p>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <div className="alert alert-info flex flex-col justify-start text-start items-start text-sm gap-1 mt-2">  <b>Just so you know</b> Your progress saves automatically. The numbers might not change, but we've got you covered!  </div>

      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission}
        disabled={!swotSubmission.response || countWords(swotSubmission.response) < 10}
      >
        Submit
      </button>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 2:</b> Tracking Social Media Success Metrics
      </h2>
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek7_1
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 4:</b> True/False Questions
      </h2>

      <QuizComponent questionsData={questionsData2} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}  />
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Objectives";

      case 2:
        return "Starting your Journey ";

      case 3:
        return "Toolkit";

      case 4:
        return "Ethics in Critical thinking";

      case 5:
        return "Analytical Riddles & Critical Challenges";

      case 6:
        return "Activity – Argument Analysis";

      case 7:
        return "Conclusion";

      default:
        return "Page";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary flex flex-col md:flex-row shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              disabled={([3,6].includes(currentStep) && !isOptionSelected) || (currentStep === 4 && !isSubmitted)}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={[6].includes(currentStep) && !isOptionSelected}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week8;
