import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import SliderWeek4 from "./SliderWeek4";
import CardFlip from "./CardFlip";
import SliderWeek5 from "./SliderWeek5";
import Collapse from "../components/Collapse";
import Quote from "../components/Quote";

import Confetti from "react-confetti";
import "./new-certi.css";
import QuizComponent from "./QuizComponent";

// import './Flip.css';
import Vimeo from "@u-wave/react-vimeo";
const Week6 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 6;
  const week_name = "week_5";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [peerFeedback, setpeerFeedback] = useState("");
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [RecheckBox, setRecheckBox] = useState(false);
  const [RecheckBox2, setRecheckBox2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teamBuilding, setteamBuilding] = useState({
    address: "",
    balance: "",
  });

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const [assignedReview, setAssigned] = useState({
    address: "",
    balance: "",
  });
  const [subFeedback, setSubFeedback] = useState("");
  const handleSubFeedback = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`,
        {
          peerid: peerid,
          subFeedback: subFeedback,
          flag: true,
        },
        config,
      )
      .then((res) => {
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  const [VideoSubmission, setVideoSubmission] = useState({
    video: "",
  });
  const [assignedVideoSubmission, setassignedVideoSubmission] = useState({
    video: "",
  });
  const [swotFeedback, setFeedback] = useState("");
  const [videoFeedback, setvideoFeedback] = useState("");
  const [videoFeedbackUser, setvideoFeedbackUser] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: 6,
          pathway: 1,
        },
        config,
      )
      .then((response) => {
        console.log("data getted", response);
        //alert("get_assigned_submission",response)
        // toast.success("Good Job! Activity Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            address: data.address,
            balance: data.balance,
          });
          setFeedback(response.data.feedback);
          setvideoFeedback(response.data.feedback);
        }
      });
  };

  const [peerid, setPeerId] = useState(0);
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);
          setteamBuilding({
            address: data.address,
            balance: data.balance,
          });
        }
      });
  };

  const fetchVideoStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          setVideoSubmission({
            video: data.video,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config,
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 7, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 6);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };
  const [rating, setRating] = useState(0);
  const handleRating = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_rating/`,
        {
          peerid: peerid,
          rating: rating,
        },
        config,
      )
      .then((res) => {
        toast.success("Good Job! Rating Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_6",
              },
              config,
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const [checkBox, setCheckBox] = useState(false);

  // const handleStepSubmission = () => {
  //   console.log("this step", currentStep);

  //   if (status === "Active" && currentStep === 6) {
  //     axios
  //       .post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
  //         user_id: user.user_id,
  //         week_name: week_name,
  //         week_id: week_id,
  //         section: 4,
  //         content: videoFeedback,
  //         pathway: 1,
  //         feedback: videoFeedback,
  //       })
  //       .then(() => {
  //         toast.success("Good Job! Feedback Submitted", {
  //           icon: "👏",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error("Error submitting feedback:", error);

  //         toast.error("Oops! Something went wrong. Please try again later.", {
  //           icon: "❌",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       });

  //     return;
  //   } else if (status === "Active" && (currentStep === 4 || currentStep === 5)) {
  //     const submissionType = currentStep === 4 ? "Activity" : "Video";
  //     axios
  //       .post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
  //         user_id: user.user_id,
  //         week_name: week_name,
  //         week_id: week_id,
  //         section: currentStep,
  //         content: currentStep === 4 ? teamBuilding : VideoSubmission,
  //         pathway: 1,
  //         status: "Reviewing",
  //       })
  //       .then(() => {
  //         toast.success('Good Job! ${submissionType} Submitted', {
  //           icon: "👏",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error submitting ${submissionType.toLowerCase()}:', error);

  //         toast.error("Oops! Something went wrong. Please try again later.", {
  //           icon: "❌",
  //           style: {
  //             borderRadius: "55px",
  //             marginBottom: "15px",
  //           },
  //         });
  //       });
  //   }
  // };
  const [disableBtn, setDisableBtn] = useState(false);

  const handleStepSubmission = () => {
    console.log("this step", currentStep);

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
        {
          user_id: user.user_id,
          week_name: week_name,
          week_id: week_id,
          section: currentStep,
          content: teamBuilding,
          pathway: 1,
          rating: 0,
          status: "Reviewing",
          pr_enabled: true,
          activity_name: "Decision Making at LUMS",
        },
        config,
      )
      .then((response) => {
        // Handle success
        console.log("Post request successful", response.data);
        setDisableBtn(true);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        // Handle error
        console.error("Error submitting activity:", error);
        // Optionally, display an error toast here if needed
      });
  };

  const isEveryFieldValid = Object.values(teamBuilding).every((value) => {
    // Check if the value exists and its length is less than 50 characters
    return value && value.length >= 50;
  });

  console.log("act: ", teamBuilding);

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const accordionData = [
    {
      title: "Focus on Roles and Relationships",
      content:
        "Recognize the unique roles each member plays in a team. Some might excel in idea generation, others in detailed planning or execution. Understanding these roles isn't just about assigning tasks; it's about creating an environment where everyone can contribute their best. Studies show that teams acknowledging and valuing each member's role tend to be more cohesive, innovative, and successful.",
    },
    {
      title: "Navigating through the Stages of Team Development",
      content:
        'Familiarize yourself with the stages of team development: forming, storming, norming, performing, and adjourning. Each stage has its challenges and opportunities. For instance, the "storming" phase, where conflicts often arise, is also a time for growth and setting clear norms. By guiding your team through these stages, you can build a strong foundation for effective collaboration and problem-solving.',
    },
    {
      title: "Diversity as a Strength",
      content:
        "Embrace diversity in its broadest sense - including thought, background, and perspective. Diverse teams bring a variety of viewpoints and solutions to the table, which is crucial in tackling complex problems. Encouraging open and respectful communication among diverse team members leads to more innovative and inclusive solutions, paving the way for a more dynamic and adaptable team.",
    },
  ];

  const questionsData = [
    {
      question:
        "Conflicts in student societies are always detrimental and should be strictly avoided.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation: "",
    },
    {
      question:
        "Effective conflict resolution means one party wins and the other loses.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation: "",
    },
    {
      question:
        "Addressing conflicts early in student-run projects prevents them from escalating and disrupting group dynamics",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      explanation: "",
    },
    {
      question:
        "A structured approach to conflict resolution is unnecessary in student societies if members have a good rapport",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
      explanation: "",
    },
    {
      question:
        "Successfully resolving conflicts can lead to a deeper understanding and stronger relationships.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
      explanation: "",
    },
  ];

  const books = [
    "Crucial Conversations: Tools for Talking when Stakes Are High by Kerry Patterson, Chapter 7 – How to Transform Anger and Hurt Feelings into Powerful Dialogue",
    "Business Communication: Developing Leaders for a Networked World by Peter Cardon, Chapter 4 – Crafting Messages for Electronic Media",
    "Just Listen by Mark Goulston, Chapter 4 – The Power of Listening",
    "Interpersonal Communication by Sarah Trenholm and Arthur Jensen, Chapter 7 – The Skills of Listening",
    "10 Ways to Have a Better Conversation by Celeste Headlee",
  ];
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  //   const [currentQuestion, setCurrentQuestion] = useState(0);
  //   const [selectedOption, setSelectedOption] = useState('');
  //   const [score, setScore] = useState(0);

  //   const handleOptionSelect = (option) => {
  //     setSelectedOption(option);
  //   };

  //   const handleNextQuestion = () => {
  //     if (selectedOption === questionsData[currentQuestion].correctAnswer) {
  //       setScore(score + 1);
  //     }
  //     setSelectedOption('');
  //     setCurrentQuestion(currentQuestion + 1);
  //   };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week5Images");

    if (!storedImages) {
      // If not in localStorage, fetch the images
      const slideImages = [
        "https://res.cloudinary.com/ahbranding/image/upload/v1702658304/AHbranding/Slide_16_9_-_21_i64zk5.png",
        "https://res.cloudinary.com/ahbranding/image/upload/v1702658302/AHbranding/Slide_16_9_-_22_q3uvbv.png",
        "https://res.cloudinary.com/ahbranding/image/upload/v1702658301/AHbranding/Slide_16_9_-_24_ltscgi.png",
        "https://res.cloudinary.com/ahbranding/image/upload/v1702658303/AHbranding/Slide_16_9_-_23_ciujdn.png",
        "https://res.cloudinary.com/ahbranding/image/upload/v1702658303/AHbranding/Slide_16_9_-_25_zrubra.png",
        // Add other image URLs as needed
      ];

      // Store the images in localStorage
      localStorage.setItem("week5Images", JSON.stringify(slideImages));
    }
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const cardData = [
    {
      id: 1,
      frontContent: "Self-Reflection and Role Clarification ",
      backContent: (
        <div>
          <p>Reflect on your role and impact within the team. Ask yourself:</p>
          <ul style={{ listStyle: "disc" }}>
            <li>How do I view my leadership role in my student society?</li>
            <li>
              Have I communicated my leadership approach and goals clearly to
              team members?
            </li>
            <li>
              What steps am I taking to ensure my actions are in line with the
              team’s objectives and values?
            </li>
          </ul>
        </div>
      ),
    },

    {
      id: 2,
      frontContent: "Assess Leadership Impact and Growth ",
      backContent: (
        <div>
          <p>
            Examine the effects of your leadership style on the team. Consider:
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>What positive changes have occurred under my leadership? </li>
            <li>
              Where could I enhance my approach to support and guide my team
              more effectively?
            </li>
            <li>
              How do my decisions and behavior mirror my commitment to the
              team's collective success?
            </li>
          </ul>
        </div>
      ),
    },

    {
      id: 3,
      frontContent: "Encourage and Value Feedback ",
      backContent: (
        <div>
          <p>Foster an environment that embraces feedback. Ponder on:</p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              How actively do I seek and apply feedback from my team members?{" "}
            </li>
            <li>
              In what ways do I promote open communication and constructive
              feedback within the team?
            </li>
            <li>
              What lessons have I learned from my team's input, and how have
              they influenced my leadership style?{" "}
            </li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];

  const cardData2 = [
    {
      id: 1,
      frontContent: "Inclusive Deliberation ",
      backContent: (
        <div>
          <p>
            In a team setting, especially within student societies, it's crucial
            to create a space where all members feel comfortable sharing their
            ideas and opinions. Effective team decision-making starts with
            inclusive deliberation, where diverse viewpoints are not only
            encouraged but are seen as essential for a well-rounded decision.
          </p>
        </div>
      ),
    },

    {
      id: 2,
      frontContent: "Analytical Approach ",
      backContent: (
        <div>
          <p>
            Teams should employ an analytical approach to decision-making. This
            involves gathering relevant information, evaluating options based on
            facts and data, and considering the potential impact of each choice.
            This systematic approach helps teams avoid biases and make informed
            decisions.
          </p>
        </div>
      ),
    },

    {
      id: 3,
      frontContent: "Consensus Building",
      backContent: (
        <div>
          <p>
            Reaching a consensus might not always mean unanimous agreement but
            finding a solution that the entire team can support. This involves
            negotiation and sometimes compromise, ensuring that decisions made
            are in the best interest of the team and its goals.
          </p>
        </div>
      ),
    },

    {
      id: 4,
      frontContent: "Action and Evaluation ",
      backContent: (
        <div>
          <p>
            After a decision is made, the team should put it into action and
            monitor its outcomes. This phase is crucial for learning and growth,
            as it allows the team to evaluate the effectiveness of their
            decision and make adjustments if necessary.
          </p>
        </div>
      ),
    },
  ];

  const questionsData1 = [
    {
      question:
        "A well-drafted event proposal should always include a clear timeline with specific deadlines for key tasks.",
      options: ["True", "False"],
      correctAnswer: "True",
    },

    {
      question:
        "Knowing your target audience is key to creating activities and marketing strategies that resonate with them.",
      options: ["True", "False"],
      correctAnswer: "True",
    },

    {
      question:
        "A proposal with vague objectives, undefined budgets, and no marketing plan can still be successful if the event idea is good enough.",
      options: ["True", "False"],
      correctAnswer: "False",
    },

    {
      question:
        " An event proposal is primarily used for internal team discussions and does not need to appeal to sponsors or stakeholders.",
      options: ["True", "False"],
      correctAnswer: "False",
    },

    {
      question:
        "A contingency fund in your budget helps address unexpected expenses or last-minute changes.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
  ];

  const scenarioData1 = [
    {
      question: "How should they integrate the awareness content?",
      options: [
        "Highlight MuSoc with a minor mention of FemSoc and the cause.",
        "Focus entirely on breast cancer awareness with little LMS visibility.",
        "Combine both societies’ names and logos, with a unified event name like “Pink Beats: A Jam Session for Breast Cancer Awareness”.",
      ],
      correctAnswer:
        "Combine both societies’ names and logos, with a unified event name like “Pink Beats: A Jam Session for Breast Cancer Awareness”.",
      explanation:
        "Balances visibility, aligns with the cause, and leverages both audiences effectively",
    },
  ];

  const scenarioData2 = [
    {
      question: "What branding approach should they take?",
      options: [
        "Dedicate a 10-minute educational talk in the middle of the event.",
        "Share brief breast cancer awareness messages between songs, keeping the music flowing.",
        "Collaborate on a creative musical piece with lyrics about breast cancer awareness.",
      ],
      correctAnswer:
        "Collaborate on a creative musical piece with lyrics about breast cancer awareness.",
      explanation:
        "Creates a unique, memorable experience that ties the cause and the jam session seamlessly",
    },
  ];

  const scenarioData3 = [
    {
      question: "How should they allocate sponsorship visibility and funds?",
      options: [
        "Divide funds and visibility equally between the two societies.",
        "Allocate more funds and control to MuSoc while crediting FemSoc for securing the sponsorship.",
        "Assign primary sponsorship recognition to FemSoc while MuSoc manages logistics and retains some control.",
      ],
      correctAnswer:
        "Assign primary sponsorship recognition to FemSoc while MuSoc manages logistics and retains some control.",
      explanation:
        "Elevates FemSoc’s visibility while leveraging MuSoc’s operational strengths",
    },
  ];

  const scenarioData4 = [
    {
      question:
        " How should they ensure FemSoc’s message reaches the audience effectively?",
      options: [
        "Pause the music briefly for FemSoc to address the audience directly.",
        "Project a slideshow with breast cancer awareness facts and resources during performances.",
        "Use emcees to weave awareness messages into transitions between musical performances.",
      ],
      correctAnswer:
        "Use emcees to weave awareness messages into transitions between musical performances.",
      explanation:
        "Keeps the energy intact and reinforces the cause through consistent messaging",
    },
  ];

  const questionsData2 = [
    {
      question:
        "The DRUMS and LMA decide to co-host an event: a debate screening series, where film clips are analysed through debates. During planning, both societies argue over which content to prioritize, debates or film analysis. What is the best approach to resolve the conflict and ensure a balanced event?",
      options: [
        "Alternate between debates and film screenings, giving both societies equal focus.",
        "Let the Debate Society take the lead since they initiated the collaboration.",
        "Decide based on which society can attract more attendees.",
        "Integrate debates and film analysis in a single cohesive format where both are equally represented.",
      ],
      correctAnswer:
        "Integrate debates and film analysis in a single cohesive format where both are equally represented.",
      explanation:
        "Integrating both elements ensures a balanced approach, showcasing the strengths of both societies while appealing to a wider audience.",
    },

    {
      question:
        "The SLUMS and LUMS Cultural Society partner for a Sports and Creativity Festival, securing a sponsorship of Rs. 200,000. SLUMS suggests spending most of the funds on venue rentals and equipment, while the Cultural Society wants to allocate more to decor and branding. How should the sponsorship funds be allocated?",
      options: [
        "Divide the sponsorship equally between both societies.",
        "Allocate funds based on which activities are expected to attract more participants.",
        "Prioritize shared resources, such as marketing, that benefit both societies.",
        "Let SLUMS control the funds as they secured the venue.",
      ],
      correctAnswer:
        "Prioritize shared resources, such as marketing, that benefit both societies.",
      explanation:
        "Prioritizing shared resources ensures fairness while maximizing the value for both societies.",
    },

    {
      question:
        "LCSS collaborates LEAF for a Sustainability Fair. LCSS wants the event name to emphasize their branding, while LEAF insists on a name that highlights sustainability. How can both societies balance branding for the event?",
      options: [
        "Highlight the LCSS’s role to attract sponsors.",
        "Use a name that prioritizes sustainability to reflect the event’s purpose.",
        "Develop a co-branded name that highlights both societies’ contributions, like “Green Marketing Fair.”",
        "Avoid using either society’s name to prevent disputes.",
      ],
      correctAnswer:
        "Develop a co-branded name that highlights both societies’ contributions, like “Green Marketing Fair.”",
      explanation:
        "A co-branded name acknowledges both societies’ efforts and aligns with the event’s purpose.",
    },

    {
      question:
        "PLUMS partners with the Dramaline for a Playwriting Workshop and Performance Night. PLUMS plans a 1-hour writing workshop, followed by a 2-hour drama performance. Midway through the workshop, attendees start losing interest. What should the societies do to re-engage the audience?",
      options: [
        "Shorten the writing workshop and move directly to the performance.",
        "Introduce interactive activities like a group writing exercise during the workshop.",
        "Focus on promoting the performance and minimize workshop time.",
        "Proceed with the planned schedule, assuming interest will pick up later.",
      ],
      correctAnswer:
        "Introduce interactive activities like a group writing exercise during the workshop.",
      explanation:
        "Interactive activities make the workshop engaging without compromising its educational value, benefiting both societies.",
    },

    {
      question:
        "The LUMS Entrepreneurial Society and LUMS Economic Society organize a Startup Challenge. During the event, disagreements arise over who should announce the winners and take credit for the event’s success. How should the societies resolve the conflict?",
      options: [
        "Alternate between representatives of both societies for announcements.",
        "Let LES take charge since they secured the prizes.",
        "Announce the winners together, emphasizing the event’s collaborative nature.",
        "Avoid any announcements and post the results online later.",
      ],
      correctAnswer:
        "Announce the winners together, emphasizing the event’s collaborative nature.",
      explanation:
        "A joint announcement reinforces collaboration and ensures both societies share credit equally.",
    },
  ];

  const steps = [
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 6: Building and Maintaining High Performance teams
      </h2>
      <p className="mt-4 text-gray-600">
        Planning events can be both exciting and overwhelming. This week, we’ll
        break it down for you step by step. From drafting impactful event
        proposals to budgeting, marketing, and collaboration, you’ll learn
        everything you need to execute memorable and successful events. We’ll
        also talk about backup plans because, let’s face it, things don’t always
        go as planned!
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Conflicts in student societies often arise due to unclear roles; defining responsibilities reduces misunderstandings."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                clqssName="w-11/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736760909/rafiki_eeps0o.png"
              />
            </div>
          </div>
        </div>
      </div>

      </section>,
    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      <section
        className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
        style={{ height: "100%" }}
      >
        <h2 className="text-xl font-medium sm:text-xl">
          <b>Drafting a Comprehensive Event Proposal</b>
        </h2>
        <div className="mt-6 flex justify-start w-full">
          <SliderWeek5
            className="w-full"
            onImageIndexChange={handleImageIndexChange}
          />
        </div>
      </section>
      ,
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> True/False Quiz
      </h2>

      <QuizComponent
        questionsData={questionsData1}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 2:</b> Collaborating with Other Societies
      </h2>

      <h2 class="text-xl font-bold mb-4">Scenario Overview:</h2>
      <p class="mb-4">
        The LUMS Music Society (MuSoc), a large society on campus, plans to host
        a Jam Session for Breast Cancer Awareness. They collaborate with the
        smaller FemSoc to align the event with the cause. MuSoc brings its
        extensive platform, audience, and event expertise, while FemSoc offers
        deep insights into breast cancer awareness and access to relevant
        sponsors.
      </p>
      <p class="mb-4">
        However, as planning begins, both societies face the following
        challenges:
      </p>
      <ol class="list-decimal list-inside mb-4">
        <li class="mb-2">
          <strong>Balancing Visibility:</strong> FemSoc wants recognition for
          their cause but fears being overshadowed by MuSoc’s dominant presence.
        </li>
        <li class="mb-2">
          <strong>Maintaining Engagement:</strong> LMS worries that awareness
          content might reduce the audience's interest in the jam session.
        </li>
        <li>
          <strong>Resource Allocation:</strong> Both societies must decide how
          to share sponsorships, funds, and responsibilities.
        </li>
      </ol>
      <p>
        Your role is to guide the collaboration and help both societies achieve
        their goals.
      </p>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Scenario 1:</b> Event Branding and Promotion
      </h2>

      <p class="mb-4">
        MuSoc and FemSoc must agree on how to brand the event and design
        promotional materials.
      </p>
      <ul class="list-disc list-inside">
        <li class="mb-2">
          <strong>MuSoc:</strong> Proposes emphasizing their jam session to draw
          their usual large audience.
        </li>
        <li>
          <strong>FemSoc:</strong> Insists the branding should focus on breast
          cancer awareness to reflect the cause.
        </li>
      </ul>

      <QuizComponent
        questionsData={scenarioData1}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Scenario 2:</b> Content Integration
      </h2>

      <p class="mb-4">
        FemSoc suggests a 10-minute segment during the jam session to discuss
        breast cancer awareness. MuSoc is concerned about losing audience
        interest with a long pause in the music.
      </p>

      <QuizComponent
        questionsData={scenarioData2}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Scenario 3:</b> Sponsorship and Resource Allocation
      </h2>

      <p class="mb-4">
        FemSoc secures sponsorship from a healthcare organization and requests
        that their logo and contributions be prominently displayed. MuSoc,
        however, is providing the venue, equipment, and logistics and argues for
        greater control over funds.
      </p>

      <QuizComponent
        questionsData={scenarioData3}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Scenario 4:</b> Event Day Dynamics
      </h2>

      <p class="mb-4">
        On the event day, FemSoc notices that the audience is mainly engaging
        with the music and less with the awareness cause. FemSoc worries their
        message is being lost, while MuSoc wants to keep the energy high.
      </p>

      <QuizComponent
        questionsData={scenarioData4}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 2:</b> Scenario Based MCQ Quiz
      </h2>

      <QuizComponent
        questionsData={questionsData2}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
        s
      />
    </section>,
    // <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     Team Building Introduction video
    //   </h2>

    //   <div className="mt-6 flex justify-center w-full h-full">
    //     <div
    //       style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
    //     >
    //       <div className="video-container">
    //         <Vimeo video={900350063} autoplay />
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Understanding Team Dynamics </b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     Have you ever been part of a group where everyone worked harmoniously
    //     towards a common goal? Or perhaps a team where, despite everyone's best
    //     efforts, things just didn't click? These scenarios highlight the
    //     importance of understanding team dynamics, the invisible forces that
    //     shape how a team functions and achieves its objectives.
    //     <br /> <br />
    //     Team dynamics are not about aligning people who think and work the same
    //     way, but about harnessing the diversity of thoughts, skills, and
    //     approaches. The most effective teams are those that balance
    //     complementary strengths and weaknesses, creating a synergy that goes
    //     beyond individual capabilities. Let's dive into how we can develop a
    //     deeper appreciation and practical know-how of team dynamics.
    //   </p>

    //   <div className="mt-6 gap-6">
    //     <div className="space-y-4">
    //       <Collapse accordionData={accordionData} />

    //       <p>
    //         Understanding team dynamics is not just about managing a group of
    //         people. It's about fostering a culture of mutual respect, open
    //         communication, and shared goals. As you embark on this journey of
    //         understanding and nurturing team dynamics, remember that the
    //         strength of a team lies in its ability to unite diverse individuals
    //         towards a common purpose.
    //       </p>
    //     </div>
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Enhancing Leadership Skills within Teams </b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     For student leaders at LUMS, especially those in student societies,
    //     regularly honing leadership skills is as important as an athlete's
    //     routine training to stay at peak performance. Consistently refining your
    //     approach to leadership within teams ensures you’re effectively guiding
    //     and inspiring your peers.
    //   </p>

    //   <div className="mt-6 gap-6">
    //     <div className="grid grid-cols-1 ">
    //       {cardData.map((card) => (
    //         <CardFlip
    //           key={card.id}
    //           frontContent={card.frontContent}
    //           backContent={card.backContent}
    //         />
    //       ))}
    //     </div>
    //   </div>
    // </section>,
    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek5
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />
    //   </div>
    // </section>,
    // // <section
    // //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    // //   style={{ height: "100%" }}
    // // >
    // //   <div className="mt-6 flex justify-start w-full">
    // //     <SliderWeek5
    // //       className="w-full"
    // //       onImageIndexChange={handleImageIndexChange}
    // //     />
    // //   </div>
    // // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b></b>
    //   </h2>
    //   <p className="mt-4 text-gray-600"></p>

    //   <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
    //     {currentQuestion < questionsData.length ? (
    //       <div>
    //         <h2 className="text-xl text-center font-semibold mb-8">
    //           {questionsData[currentQuestion].question}
    //         </h2>
    //         <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
    //           {questionsData[currentQuestion].options.map((option, index) => (
    //             <div
    //               key={index}
    //               className={`${
    //                 showExplanation &&
    //                 option === questionsData[currentQuestion].correctAnswer
    //                   ? "bg-green-500 text-white"
    //                   : selectedOption === option
    //                   ? "bg-purple-200 "
    //                   : "bg-white"
    //               } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer `}
    //               onClick={() => handleOptionSelect(option)}
    //             >
    //               {option}
    //             </div>
    //           ))}
    //         </div>
    //         {selectedOption && (
    //           <div className="flex justify-center">
    //             <button
    //               className="btn btn-outline-primary mt-4"
    //               onClick={handleNextQuestion}
    //             >
    //               Show Answer
    //             </button>
    //           </div>
    //         )}
    //         {showExplanation && (
    //           <div className="mt-4">
    //             <p className="font-semibold">Correct Answer:</p>
    //             <p className="text-green-500">
    //               {questionsData[currentQuestion].correctAnswer}
    //             </p>
    //             {/*<p className="font-semibold mt-2">Explanation:</p>*/}
    //             <p>{questionsData[currentQuestion].explanation}</p>
    //             <div className="flex justify-center mt-4">
    //               <button
    //                 className="btn btn-outline-primary"
    //                 onClick={handleNextQuestionOrFinish}
    //               >
    //                 {currentQuestion + 1 < questionsData.length
    //                   ? ""
    //                   : "Good Job 🙌"}

    //                 {currentQuestion + 1 < questionsData.length
    //                   ? "Next Question"
    //                   : ""}
    //               </button>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     ) : (
    //       <div>
    //         <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
    //       </div>
    //     )}
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Decision Making as a Team at LUMS </b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     Decision making as a team is not just about reaching a consensus; it's
    //     about blending diverse perspectives to make choices that benefit the
    //     group and its objectives. Effective team decision-making involves
    //     considering various viewpoints, weighing the pros and cons, and arriving
    //     at decisions that align with the team’s goals. It's about ensuring that
    //     every voice is heard and that the final decision reflects a collective
    //     agreement.{" "}
    //   </p>

    //   <div className="mt-6 gap-6">
    //     <div className="grid grid-cols-1 ">
    //       {cardData2.map((card) => (
    //         <CardFlip
    //           key={card.id}
    //           frontContent={card.frontContent}
    //           backContent={card.backContent}
    //         />
    //       ))}
    //     </div>
    //   </div>
    // </section>,

    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   {/* Step 1 content */}
    //   <h2 className="text-xl font-medium sm:text-xl">
    //     <b>Case Study: Team Building in a LUMS Student Society </b>
    //   </h2>
    //   <p className="mt-4 text-gray-600">
    //     In the dynamic environment of university student societies, the ability
    //     to build and manage effective teams is crucial. This case study revolves
    //     around a real scenario faced by a student society at LUMS, highlighting
    //     the intricacies of team building and management.
    //     <br /> <br />
    //     The society planned a major campus event, involving various departments
    //     like logistics, marketing, and finance. However, as the event neared,
    //     conflicts arose due to differing opinions and work styles, threatening
    //     the event's success. The society's leaders had to navigate these
    //     challenges, ensuring effective collaboration and maintaining team
    //     morale. They decided to implement team-building strategies, such as
    //     facilitating open discussions to address conflicts, redistributing tasks
    //     to align with members' strengths, and organizing team bonding
    //     activities. These efforts aimed to enhance understanding and cooperation
    //     among team members. The outcomes were significant. The improved teamwork
    //     led to a successful event, with each committee efficiently fulfilling
    //     its role. The experience also fostered a stronger sense of community
    //     within the society, enhancing overall member satisfaction and
    //     engagement. However, these measures required additional time and effort,
    //     temporarily slowing down event preparations. Some members felt that the
    //     focus on team-building activities was a distraction from the practical
    //     tasks at hand.
    //   </p>

    //   <ul
    //     className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    //         />
    //       </svg>
    //       Instructions{" "}
    //     </p>
    //     <li className="m-6">
    //       Read the Case Study: Carefully review the narrative about the LUMS
    //       student society’s approach to team building.{" "}
    //     </li>
    //     <li className="m-6">
    //       Develop Your Response: Reflect on the essential aspects of team
    //       building based on this scenario.
    //     </li>
    //   </ul>

    //   <ul
    //     className="mt-4 bg-purple-50 p-2 rounded-xl border-2 border-purple-100 text-sm"
    //     style={{ listStyle: "circle" }}
    //   >
    //     <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-purple-400 text-white w-max py-1 px-3 rounded-full">
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke-width="1.5"
    //         stroke="currentColor"
    //         class="w-6 h-6"
    //       >
    //         <path
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //           d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
    //         />
    //       </svg>
    //       Response Guidelines{" "}
    //     </p>
    //     <li className="m-6">
    //       Your response should be concise, ideally 5-10 lines.
    //     </li>

    //     <li className="m-6">
    //       Identify key elements from the case study that inform your
    //       understanding of team building.{" "}
    //     </li>

    //     <li className="m-6">
    //       Consider the balance between team cohesion and task efficiency.{" "}
    //     </li>
    //   </ul>

    //   {status === "Completed" || disableBtn ? (
    //     <div className="my-4">
    //       <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
    //         <ul>
    //           <li>
    //             <b>Address:</b>
    //             <br /> {teamBuilding?.address}
    //             <br />
    //             <br />
    //           </li>
    //           <li>
    //             <b>Balance:</b>
    //             <br /> {teamBuilding?.balance}
    //             <br />
    //             <br />
    //           </li>
    //         </ul>
    //       </p>
    //     </div>
    //   ) : (
    //     <div>
    //       <div className="mt-6 flex justify-start w-full">
    //         <div className="form-control w-full ">
    //           <label className="label">
    //             <span className="label-text">
    //               <b>
    //                 Addressing Conflicts in Team Settings: : <br />
    //                 <br />
    //               </b>
    //               How can student leaders effectively address conflicts within
    //               teams to ensure the success of society events and activities?{" "}
    //             </span>
    //           </label>
    //           <textarea
    //             value={teamBuilding.address ?? ""}
    //             onChange={(e) =>
    //               setteamBuilding((prev) => ({
    //                 ...prev,
    //                 address: e.target.value,
    //               }))
    //             }
    //             className="textarea textarea-sm textarea-bordered h-20"
    //             placeholder="Type here.."
    //           ></textarea>
    //         </div>
    //       </div>

    //       <div className="mt-6 flex justify-start w-full">
    //         <div className="form-control w-full ">
    //           <label className="label">
    //             <span className="label-text">
    //               <b>
    //                 Balancing Team Strengths and Weaknesses: : <br />
    //                 <br />
    //               </b>
    //               How can understanding and leveraging the diverse strengths and
    //               weaknesses of team members contribute to the success of
    //               student-run projects?{" "}
    //             </span>
    //           </label>
    //           <textarea
    //             value={teamBuilding.balance ?? ""}
    //             onChange={(e) =>
    //               setteamBuilding((prev) => ({
    //                 ...prev,
    //                 balance: e.target.value,
    //               }))
    //             }
    //             className="textarea textarea-sm textarea-bordered h-20"
    //             placeholder="Type here..."
    //           ></textarea>
    //         </div>
    //       </div>

    //       <div className="flex items-center gap-2 mt-6">
    //         <input
    //           type="checkbox"
    //           checked={checkBox}
    //           className="checkbox checkbox-warning"
    //           onChange={() => {
    //             setCheckBox(!checkBox); // Toggle the checkbox state
    //           }}
    //         />
    //         <span className="text-black-400 ">
    //           Do you agree to share your response with a peer for the purpose of
    //           receiving constructive feedback.{" "}
    //         </span>
    //       </div>
    //       <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
    //         <input
    //           type="checkbox"
    //           className="checkbox checkbox-warning "
    //           checked={RecheckBox}
    //           onChange={() => {
    //             setRecheckBox(!RecheckBox); // Toggle the checkbox state
    //           }}
    //         />
    //         <span className="text-black-400 ">
    //           Read your submission carefully before submitting
    //         </span>
    //       </div>

    //       <button
    //         className="btn btn-primary mt-8 bg-neutral-50"
    //         onClick={handleStepSubmission}
    //         disabled={
    //           status === "Completed" ||
    //           !RecheckBox ||
    //           !checkBox ||
    //           disableBtn ||
    //           !isEveryFieldValid
    //         }
    //       >
    //         Submit
    //       </button>
    //     </div>
    //   )}
    // </section>,

    // <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //   {/* Step 1 content */}

    //   <Confetti numberOfPieces={100} />

    //   <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //     <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
    //       <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //         <img
    //           className="w-full"
    //           src="https://res.cloudinary.com/ahbranding/image/upload/v1701669887/AHbranding/Group_34784453_1_dkh3wp.png"
    //         />
    //       </div>

    //       <div className="">
    //         <h2 className="text-xl font-medium sm:text-xl">
    //           <b>
    //             Congratulations on Completing Week 5: Empathy - The Heart of
    //             Leadership!
    //           </b>
    //         </h2>
    //         <p className="mt-4 text-gray-600">
    //           As we conclude our exploration into the essence of team building,
    //           we recognize the transformative power it holds in leadership. This
    //           week, we've delved deep into understanding, feeling, and acting in
    //           team settings, using it as a tool to forge genuine connections and
    //           lead with authenticity.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </section>,
  ];
 
const getTitle = (currentStep) => {
  switch (currentStep) {
    case 0:
      return "Introduction";
    case 1:
      return "Drafting a Comprehensive Event Proposal";
    case 2:
      return "Activity 1: True/False Quiz";
    case 3:
      return "Collaborating with Other Societies";
    case 4:
      return "Scenario 1: Event Branding and Promotion";
    case 5:
      return "Scenario 2: Content Integration";
    case 6:
      return "Scenario 3: Sponsorship and Resource Allocation";
    case 7:
      return "Scenario 4: Event Day Dynamics";
    case 8:
      return "Activity 2: Scenario-Based MCQ Quiz";
    default:
      return "Peer";
  }
};
  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary flex flex-col md:flex-row shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              // disabled={
                              //   currentStep === 7 &&
                              //   (!disableBtn || !status === "Completed")
                              // }

                              disabled={
                                [2, 4, 5, 6, 7, 8].includes(currentStep) &&
                                !isOptionSelected
                              }
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={
                                [8].includes(currentStep) &&
                                !isOptionSelected
                              }
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week6;
