import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import Collapse from "../components/Collapse";
import Confetti from "react-confetti";
import "./new-certi.css";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";



const Week2 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 2;
  const week_name = "week_1";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  console.warn(server_progress, "server_progress");
  // const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [currentStep, setCurrentStep] = useState(0);
  const [checkBox, setCheckBox] = useState(false);
  const [rating, setRating] = useState(0);
  const [peerid, setPeerId] = useState(0);
  const [flag, setFlag] = useState(false);
  const [subFeedback, setSubFeedback] = useState("");
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week1Images");
    const slideImages = [
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649088/Slide_16_9_-_93_nnkdkv.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649087/Slide_16_9_-_94_gqv0bc.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649086/Slide_16_9_-_95_nnyh34.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649084/Slide_16_9_-_96_wxwkqi.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649084/Slide_16_9_-_97_r2rbzx.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_98_g7ai3l.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_99_ckm4kj.png",
      "https://res.cloudinary.com/dmv23kuoc/image/upload/v1735649085/Slide_16_9_-_100_ibk0xj.png",
      // Add other image URLs as needed
    ];
  }, []);

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const storedSwotData = localStorage.getItem("weekOneSwotData");
  const localSwotData = JSON.parse(storedSwotData);
  // const [swotSubmission, setSubmission] = useState({
  //   strengths: localSwotData?.strengths ?? "",
  //   weaknesses: localSwotData?.weaknesses ?? "",
  //   opportunities: localSwotData?.opportunities ?? "",
  //   threats: localSwotData?.threats ?? "",
  //   // peer_feedback: localSwotData?.peer_feedback ?? "",
  // });
  const [swotSubmission, setSubmission] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
    // peer_feedback: "",
  });

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  const [swotSubmission2, setSubmission2] = useState({
    response: "",
  });
  const [disableBtn, setDisableBtn] = useState(false);
  const [disableBtnTwo, setDisableBtnTwo] = useState(false);

  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
          });
          setFeedback(response.data.feedback);
        }
      });
  };

  // function countWords(text) {
  //   const trimmedText = text?.trim();
  //   const words = trimmedText?.split(/\s+/);
  //   return words?.length;
  // }

  function countWords(text) {
    if (typeof text !== "string") {
      return 0; // Return 0 if text is not a string
    }
    const trimmedText = text.trim();
    const words = trimmedText.split(/\s+/);
    return words.length;
  }

  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);

          setSubmission({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
            peer_feedback: data.feedback,
          });

          // Store setSubmission in localStorage

          // setSubmission2({
          //   response: data.response,
          // });
        }
      });
  };

  const fetchStepData2 = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);

          // setSubmission({
          //   strengths: data.strengths,
          //   weaknesses: data.weaknesses,
          //   opportunities: data.opportunities,
          //   threats: data.threats,
          //   peer_feedback: data.feedback,
          // });

          // Store setSubmission in localStorage

          setSubmission2({
            response: data.response,
          });
        }
      }, config);
  };
  const [RecheckBox, setRecheckBox] = useState(false);
  const [RecheckBox2, setRecheckBox2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchStepData2(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if ( currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_2",
              },
              config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const [peerFeedback, setpeerFeedback] = useState("");
  const handleSubFeedback = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`,
        {
          peerid: peerid,
          subFeedback: subFeedback,
          flag: true,
        },
        config
      )
      .then((res) => {
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const areStrengthsValid = countWords(swotSubmission?.strengths) >= 50;
  const areResponseValid = countWords(swotSubmission2?.response) >= 300;
  const areWeaknessesValid = countWords(swotSubmission?.weaknesses) >= 50;
  const areOpportunitiesValid = countWords(swotSubmission?.opportunities) >= 50;
  const areThreatsValid = countWords(swotSubmission?.threats) >= 50;

  const isAnySectionInvalid =
    !areStrengthsValid ||
    !areWeaknessesValid ||
    !areOpportunitiesValid ||
    !areThreatsValid;
  const handleRating = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_rating/`,
        {
          peerid: peerid,
          rating: rating,
        },
        config
      )
      .then((res) => {
        toast.success("Good Job! Rating Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);

    if (status === "Active" && currentStep === 4) {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission,
            pathway: 1,
            status: "Reviewing",
            rating: rating,
            pr_enabled: true,
            activity_name: "SWOT Analysis",
          },
          config
        )
        .then((response) => {
          // Handle success
          setDisableBtn(true);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          // Additional handling if needed
        })
        .catch((error) => {
          // Handle error
          toast.error("Error submitting activity:", error);
          // Additional error handling if needed
        });
    } else if (status === "Active" && currentStep === 5) {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission2,
            pathway: 1,
            status: "Reviewing",
            rating: rating,
            pr_enabled: true,
            activity_name: "SWOT Analysis22",
          },
          config
        )
        .then((response) => {
          // Handle success
          setDisableBtn(true);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          // Additional handling if needed
        })
        .catch((error) => {
          // Handle error
          toast.error("Error submitting activity:", error);
          // Additional error handling if needed
        });
    }
  };

  const handleStepSubmission2 = () => {
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission2,
            pathway: 1,
            status: "Reviewing",
            rating: rating,
            pr_enabled: false,
            activity_name: "Self Reflection Journaling",
          },
          config
        )
        .then((response) => {
          // Handle success
          setDisableBtnTwo(true);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        })
        .catch((error) => {
          // Handle error
          console.error("Error submitting activity:", error);
          toast.error("Error submitting activity. Please try again later.", {
            icon: "❌",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        });
    }
  };

  if (currentStep === 4 && !areResponseValid && isAnySectionInvalid) {
    console.warn("Condition is true LINE 439");
  } else {
    console.warn("Condition is false LINE 441");
  }

  const totalSteps = 12; 

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  useEffect(() => {
    // Check if swotSubmission is defined and not null
    if (swotSubmission !== undefined && swotSubmission !== null) {
      const storedData = localStorage.getItem("weekOneSwotData");

      // Check if storedData exists in localStorage
      if (!storedData) {
        const data = {
          strengths: swotSubmission.strengths || [],
          opportunities: swotSubmission.opportunities || [],
          threats: swotSubmission.threats || [],
          peer_feedback: swotSubmission.peer_feedback || [], // Adjust according to your data structure
        };

        // Log data before saving to localStorage
        console.log("Data to be stored:", data);

        localStorage.setItem("weekOneSwotData", JSON.stringify(data));
      } else {
        // Log a message if data already exists in localStorage
        console.log("Data already exists in localStorage:", storedData);
      }
    }
  }, [swotSubmission]);

  // useEffect(() => {

  //   if (currentStep === 0 ) {
  //     setNextBtnCondtion(false);
  //   } else {
  //     setNextBtnCondtion(true);
  //   } if(currentStep === 2 && currentImageIndex < 6) {
  //     setNextBtnCondtion(true);
  //   } else {
  //     setNextBtnCondtion(false);
  //   } if ((currentStep === 4 && !Object.values(swotSubmission).some(value => value !== '')) || !disableBtn) {
  //     setNextBtnCondtion(true);
  //   } else {
  //     setNextBtnCondtion(false);
  //   }
  // }, [currentStep, currentImageIndex, areResponseValid, isAnySectionInvalid,disableBtn, disableBtnTwo]);

  useEffect(() => {
    if (currentStep === 0 || currentStep === 1 || currentStep === 3) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 2 && currentImageIndex < 6) {
      setNextBtnCondtion(false);
    } else if (currentStep === 2 && currentImageIndex > 5) {
      setNextBtnCondtion(true);
    }

    // else if (currentStep === 4 && (disableBtn || Object.values(swotSubmission).every(value => value !== ''))) {
    //   setNextBtnCondtion(true);
    //   // alert(currentStep + disableBtn + Object.values(swotSubmission).some(value => value !== ''))
    // }
    else if (currentStep === 4 && status === "Active") {
      if (disableBtn) {
        setNextBtnCondtion(true);
      } else {
        setNextBtnCondtion(false);
      }
    } else if (currentStep === 4 && status === "Completed") {
      setNextBtnCondtion(true);
    } else if (
      status === "Completed" &&
      Object.values(swotSubmission).every((value) => value !== "")
    ) {
      setNextBtnCondtion(true);
    } else if (
      currentStep === 5 &&
      (disableBtnTwo ||
        (status === "Completed" &&
          Object.values(swotSubmission2).some((value) => value !== "")))
    ) {
      setNextBtnCondtion(true);
    } else {
      setNextBtnCondtion(false);
    }
  }, [
    currentStep,
    currentImageIndex,
    swotSubmission,
    swotSubmission2,
    areResponseValid,
    isAnySectionInvalid,
    disableBtn,
    disableBtnTwo,
  ]);

  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };

  const questionsDataScenario1 = [
    {
      question: "What might be causing Hassan’s behavior?",
      options: [
        "He’s overwhelmed with responsibilities.",
        "He’s uninterested in his role.",
        "He doesn’t care about the team’s success.",
      ],
      correctAnswer: "He’s overwhelmed with responsibilities.",
      explanation:
        "Overwhelming responsibilities, especially in high-pressure environments, can lead to missed deadlines and stress.",
    },
  ];

  const questionsDataScenario2 = [
    {
      question: "What should you do first?",
      options: [
        "Meet Hassan privately to discuss his challenges.",
        "Reassign Hassan’s tasks without informing him.",
        "Criticize Hassan’s performance during the next team meeting.",
      ],
      correctAnswer: "Meet Hassan privately to discuss his challenges.",
      explanation:
        "Excellent! A private conversation demonstrates empathy and creates a safe space for Hassan to share his challenges.",
    },
  ];

  const questionsDataScenario3 = [
    {
      question:
        "What’s the best way to support Hassan while addressing the problem?",
      options: [
        "Redistribute tasks to reduce Hassan’s workload while maintaining his involvement.",
        "Remove Hassan from his role to avoid further issues.",
        "Tell the team to handle the tasks themselves and avoid involving Hassan further.",
      ],
      correctAnswer:
        "Redistribute tasks to reduce Hassan’s workload while maintaining his involvement.",
      explanation:
        "Great choice! Redistributing tasks helps Hassan regain focus and confidence while ensuring accountability.",
    },
  ];

  const questionsDataScenario4 = [
    {
      question:
        "What leadership skill was most critical in resolving the issue?",
      options: [
        "Empathy and communication.",
        "Strict accountability without flexibility.",
        "Delegation without consultation.",
      ],
      correctAnswer: "Empathy and communication.",
      explanation:
        "Empathy and communication are key to understanding the root cause of the issue and collaboratively finding a solution.",
    },
  ];

  const questionsData1 = [
    {
      question:
        "Scenario 1: You are the president of a society planning a major event. One of your teammates seems distracted and overwhelmed. How should you respond?",
      options: [
        " Ignore the issue and focus on the event.",
        "Reprimand the teammate for not performing well.",
        "Acknowledge their emotions, offer support, and discuss their challenges.",
        "Assign their tasks to someone else without informing them.",
      ],
      correctAnswer:
        "Acknowledge their emotions, offer support, and discuss their challenges.",
      explanation:
        "Acknowledging emotions shows empathy and builds trust. Offering support and discussing challenges helps identify ways to reduce stress and improve performance.",
    },
    {
      question:
        "Scenario 2: Your team is juggling multiple deadlines and is falling behind. What is the first step to managing this stress?",
      options: [
        "Identify the triggers causing stress within the team.",
        "Work extra hours without addressing the problem.",
        "Cancel the deadlines entirely.",
        "Focus only on your own tasks and let the team handle their issues.",
      ],
      correctAnswer: "Identify the triggers causing stress within the team.",
      explanation:
        "Identifying stress triggers is the first step to addressing the root causes of the problem. Understanding the issues allows you to implement strategies that reduce stress and improve efficiency.",
    },
  ];

  const questionsData2 = [
    {
      question:
        "Scenario 1: You are leading a team project, and two members are frequently clashing over differing opinions on how to approach the task. As a leader with strong Emotional Intelligence, what is your best course of action?",
      options: [
        "Privately address the conflict by listening to both members and finding a collaborative solution.",
        "Avoid intervening to let them resolve the issue themselves and maintain independence.",
        "Decide which member has the better approach and instruct the team to follow it.",
      ],
      correctAnswer:
        "Privately address the conflict by listening to both members and finding a collaborative solution.",
      explanation:
        "An emotionally intelligent leader prioritizes empathy and collaboration, resolving conflicts privately and constructively to maintain trust and teamwork.",
    },
    {
      question:
        "Scenario 2: One of your team members is consistently underperforming and missing deadlines. During a conversation, they admit feeling overwhelmed by overlapping deadlines and personal challenges. What is the best way to support them while ensuring accountability?",
      options: [
        "Help them identify their stress triggers and redistribute their workload temporarily, while setting clear priorities.",
        "Offer them complete time off from responsibilities to address their personal challenges.",
        "Criticize their inability to manage stress and suggest they work harder to improve.",
      ],
      correctAnswer:
        "Help them identify their stress triggers and redistribute their workload temporarily, while setting clear priorities.",
      explanation:
        "Identifying stress triggers and temporarily redistributing workload demonstrates empathy while ensuring accountability. This approach balances support and productivity.",
    },
    {
      question:
        "Scenario 3: You are facing a high-pressure situation where your team needs to meet an important deadline. As a leader, how can Emotional Intelligence help you maintain team morale and performance under stress?",
      options: [
        "Stay calm under pressure, communicate clearly, and encourage open discussions about challenges.",
        "Focus solely on completing tasks efficiently without addressing team concerns or emotions.",
        "Push team members harder to ensure the deadline is met, even if it means sacrificing morale.",
      ],
      correctAnswer:
        "Stay calm under pressure, communicate clearly, and encourage open discussions about challenges.",
      explanation:
        "Emotional Intelligence helps leaders stay composed, foster open communication, and guide the team through challenges effectively.",
    },
  ];

  const questionsData3 = [
    {
      question:
        "Addressing a team member's performance issues publicly in a team meeting is an effective way to solve the problem.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Personal issues should be discussed privately to maintain trust and respect while addressing the root cause of the problem.",
    },
    {
      question:
        "Reassigning a stressed team member’s tasks without informing them helps reduce pressure and ensures team efficiency.Addressing a team member's performance issues publicly in a team meeting is an effective way to solve the problem.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Always involve the individual in decision-making to maintain transparency and a sense of inclusion.",
    },
    {
      question:
        "Meeting privately with a stressed team member to discuss their challenges is an empathetic and effective leadership approach.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "A private conversation shows empathy and provides a safe space for the team member to share their struggles, allowing for collaborative problem-solving.",
    },
    {
      question:
        "A leader should balance emotional support with accountability when dealing with a stressed team member.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        " Effective leadership involves supporting team members emotionally while ensuring responsibilities are met. Striking this balance improves both morale and performance.",
    },
    {
      question:
        "Understanding stress triggers like time pressure or miscommunication can help prevent performance issues in a team.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Recognizing and addressing stress triggers early helps prevent them from escalating into bigger problems. Leaders who do this maintain a healthier team dynamic.",
    },
  ];

  const questionsDataMulti = [
    {
      question:
        "What could Ali’s potential stress triggers be in such a situation?",
      options: [
        "Time pressure",
        "Fear of failure",
        "Excessive multitasking",
        "Poor diet",
      ],
      correctAnswer: "Time pressure",
      explanation:
        "Tight deadlines can lead to heightened stress, especially for leaders managing large-scale events.",
    },
    {
      question: "What might be causing Sara’s withdrawal?",
      options: [
        "Feeling overwhelmed",
        "Lack of support",
        "Personal conflict with Ali",
        "Team frustration",
      ],
      correctAnswer: "Feeling overwhelmed",
      explanation:
        "Sara might feel the pressure of handling logistics for such a large event.",
    },
    {
      question: "How does poor communication impact the team?",
      options: [
        "Misunderstanding",
        "Delays",
        "Conflict resolution",
        "Frustration",
      ],
      correctAnswer: "Misunderstanding",
      explanation:
        "Poor communication often leads to confusion about roles and expectations.",
    },
  ];

  const steps = [
    // Define 
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-medium sm:text-xl">
        Welcome to <b>Week 2: Emotional Intelligence</b>
      </h2>
      <p className="mt-4 text-gray-600">
        This week, we’ll dive into emotional intelligence and its importance in
        leadership. Together, we’ll uncover how to identify and manage stress,
        balance academics with society commitments, and create a supportive
        environment for your team. With guidance from CAPS, you’ll learn
        strategies to better understand yourself and those around you, making
        you a more empathetic and effective leader.
      </p>
<Quote content={"Leaders with high emotional intelligence are 4 times more likely to inspire and motivate their teams effectively."} person={""}/>


      <div className="mt-6 flex justify-center items-center h-full w-full">
        <img
        className="w-full max-w-full"
          src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1735627219/rafiki_j1vuhi.png"
        />
      </div>


    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      {/* <h2 className="text-xl font-medium sm:text-xl">
              <b>Why Emotional Intelligence Matters</b>
            </h2> */}

      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 1:</b> Introduction to Emotional Intelligence
      </h2>
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek1
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1A: </b>Scenario Based MCQs
      </h2>
      
  <QuizComponent
    questionsData={questionsData1}
    onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
  />

    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1B: </b>Scenario Based MCQs
      </h2>
      <QuizComponent questionsData={questionsData2}   onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-2xl font-medium sm:text-xl">
        <b>Section 2: Recognizing Stress Triggers</b>
      </h2>

      <h2 class="text-lg font-bold">
        LUMS Music Society’s Annual Concert Planning
      </h2>
      <h2 class="text-lg font-semibold mt-4">Scenario:</h2>
      <p class="mt-2">
        The LUMS Music Society is gearing up for its annual concert, one of the
        biggest events of the semester. The team has been working under tight
        deadlines to secure sponsors, coordinate with artists, and finalize
        logistics.
      </p>
      <p class="mt-2">
        Ali, the event head, has been under immense pressure to ensure the
        concert's success. He appears irritable during team meetings, frequently
        checks his phone for messages, and interrupts others mid-sentence.
      </p>
      <p class="mt-2">
        Sara, the logistics director, has started withdrawing from discussions.
        She avoids contributing ideas and hasn’t been keeping up with her tasks,
        leading to delays in crucial arrangements.
      </p>
      <p class="mt-2">
        The rest of the team is visibly frustrated. Deadlines are slipping, and
        communication has broken down, leaving tasks incomplete and morale low.
      </p>
      <h2 class="text-lg font-semibold">Instructions:</h2>
      <p class="mt-2">
        You are required to be a “Stress Investigator.” Looking at the above
        situation, analyze the potential stress triggers for Ali, Sara, and the
        team, and consider what strategies you would employ if tasked with
        improving this situation. After carefully analyzing the situation,
        answer the following questions.
      </p>

      <QuizComponent questionsData={questionsDataMulti}  onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}/>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Good Job!</b>
      </h2>

      <p className="mt-4 text-gray-600">
        Great! Now that you have identified the stress triggers, here are some
        proposed strategies that could be used to help improve such a situation.
        Take a look and compare it to what you had earlier considered.
      </p>

      <h4 className="text-base font-medium sm:text-base">
        <b>Proposed Strategies to Address Triggers</b>
      </h4>

      <ul>
        <li>
          <strong>Ali:</strong> Break down tasks into smaller, manageable steps
          and delegate responsibilities to reduce multitasking.
        </li>
        <li>
          <strong>Sara:</strong> Schedule a one-on-one check-in to provide
          support and encourage open dialogue about her challenges.
        </li>
        <li>
          <strong>Team:</strong> Establish a communication plan with clear
          deadlines and task ownership to improve coordination.
        </li>
      </ul>

      <h2 class="text-xl font-bold mt-6">
        Takeaway Tip: How to Identify Emotional vs. Physical Stress Signs
      </h2>
      <div class="mt-4">
        <h3 class="text-lg font-semibold">Emotional Signs:</h3>
        <ul class="list-disc list-inside ml-4">
          <li>Irritability</li>
          <li>Withdrawal</li>
          <li>Lack of motivation</li>
        </ul>
      </div>
      <div class="mt-4">
        <h3 class="text-lg font-semibold">Physical Signs:</h3>
        <ul class="list-disc list-inside ml-4">
          <li>Fatigue</li>
          <li>Headaches</li>
          <li>Changes in Appetite or Sleep Patterns</li>
        </ul>
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">
        Activity 2: Emotional Intelligence Self-Assessment
      </h2>

      <p className="mt-4 text-gray-600">
        Great! Now you may attempt to complete the following Self-Assessment worksheet.
        This extra step will enable you to understand your EI skills better.
      </p>

      <div className="flex justify-center items-center">
        <div class="mt-4">
          <a
            href="https://drive.google.com/file/d/1Qi6MPbDuE-Y2rxVQofM7FM24s8NI-jw2/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            class="px-4 py-2 bg-indigo-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Download Worksheet
          </a>
        </div>
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">
        Section 3: Supporting Team Members in Stress
      </h2>

      <h2 class="text-lg font-bold">Case Study Background:</h2>
      <p class="mt-2">
        LUMUN is gearing up for a prestigious international tournament, a
        defining moment for the team. Hassan, the Logistics Director, is
        responsible for organizing travel arrangements and accommodations for
        the participants. The pressure is high, and the team is relying on him
        to ensure a smooth trip.
      </p>
      <p class="mt-2">
        However, deadlines are being missed, creating a ripple effect on other
        preparations. Hassan seems stressed and detached during meetings. Other
        team members, feeling the impact of these delays, are growing
        increasingly frustrated.
      </p>
      <p class="mt-2">
        As an Executive Council (EC) member, it’s up to you to identify the root
        cause of the issue, support Hassan, and guide the team toward a
        solution.
      </p>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">Scenario 1</h2>

      <p class="mt-2">
        During a meeting, the frustration is palpable. Hassan avoids eye contact
        and doesn’t contribute much.
      </p>
      <p class="mt-2">Two team members voice their concerns openly:</p>
      <blockquote class="mt-2 pl-4 border-l-2 border-yellow-600 italic">
        "If we don’t confirm the bookings soon, we’ll lose the accommodations we
        wanted!"
      </blockquote>
      <blockquote class="mt-2 pl-4 border-l-2 border-yellow-600 italic">
        "Our travel plans are falling apart because nothing is finalized!"
      </blockquote>
      <h3 class="mt-4 text-lg font-semibold">Interactive Question:</h3>

      <QuizComponent questionsData={questionsDataScenario1} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">Scenario 2</h2>

      <p class="mt-2">
        The meeting has ended, but the problem remains unresolved. Hassan’s
        performance is clearly affecting the team’s morale and progress. As an
        EC member, you need to take action.
      </p>

      <h3 class="mt-4 text-lg font-semibold">Interactive Question:</h3>

      <QuizComponent questionsData={questionsDataScenario2} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">Scenario 3</h2>

      <p class="mt-2">In a one-on-one meeting, Hassan opens up:</p>

      <blockquote class="mt-2 pl-4 border-l-2 border-yellow-600 italic">
        "I’ve been overwhelmed with my coursework and responsibilities. I didn’t
        want to let the team down, so I tried to handle everything myself. But
        now, I’m struggling to keep up."
      </blockquote>

      <h3 class="mt-4 text-lg font-semibold">Interactive Question:</h3>

      <QuizComponent questionsData={questionsDataScenario3}  onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}/>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">Scenario 4</h2>

      <p class="mt-2">
        You decide to redistribute tasks among the team. Hassan will focus
        solely on confirming travel plans, while another member takes over the
        accommodations. You also check in with Hassan regularly to ensure he’s
        managing his workload better. The team feels reassured by this plan, and
        Hassan regains confidence as he starts meeting deadlines.
      </p>

      <h3 class="mt-4 text-lg font-semibold">Interactive Question:</h3>

      <QuizComponent questionsData={questionsDataScenario4} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />

      <h2 class="text-lg font-bold">
        <b>Ending Note:</b>
      </h2>

      <p class="mt-2">
        The tournament preparations are back on track. Hassan looks more
        confident and is completing tasks efficiently. The team appreciates the
        EC’s leadership in addressing the issue.
      </p>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 class="text-xl font-bold">Activity 3: True/False Based Short Quiz</h2>

      <QuizComponent questionsData={questionsData3} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Welcome to Week 2";

      case 1:
        return "Introduction to Emotional Intelligence";

      case 2:
        return "Activity 1A";

      case 3:
        return "Activity 1B";

      case 4:
        return "Recognizing Stress Triggers";

      case 5:
        return "Takeaway Tip";

      case 6:
        return "Self-Assessment";

      case 7:
        return "Supporting Team Members in Stress";
 
      case 8:
        return "Scenario 1";     
 
      case 9:
        return "Scenario 2";     
 
      case 10:
        return "Scenario 3";     

 
      case 11:
        return "Scenario 4";     



      default:
        return "Activity 3";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary flex flex-col md:flex-row shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              disabled={[2,3, 4, 8,9,10,11,12].includes(currentStep) && !isOptionSelected}

                              // disabled={!nextBtnCondtion}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={[12].includes(currentStep) && !isOptionSelected}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week2;
