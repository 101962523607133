import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import SliderWeek2 from "./SliderWeek2";
import { toast, Toaster } from "react-hot-toast";
import Collapse from "../components/Collapse";
import Confetti from "react-confetti";
import "./new-certi.css";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";


const Week3 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 3;
  const week_name = "week_2";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week2Images");

    if (!storedImages) {
      // If not in localStorage, fetch the images
      const slideImages = [
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109189/Slide_16_9_-_93_clixt4.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109189/Slide_16_9_-_94_al9bg6.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109190/Slide_16_9_-_95_oviykp.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109187/Slide_16_9_-_96_wlsbqa.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109188/Slide_16_9_-_97_ysq7l3.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736109188/Slide_16_9_-_98_s1uycz.png",
        // Add other image URLs as needed
      ];

      // Store the images in localStorage
      localStorage.setItem("week2Images", JSON.stringify(slideImages));
    }
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [peerid, setPeerId] = useState(0);
  const [peerFeedback, setpeerFeedback] = useState("");
  const [disablebtn, setDisablebtn] = useState(false);

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    result: "",
  });
  const [assignedReview, setAssigned] = useState({
    result: "",
  });
  const [emotional_regulation_practice, setemotional_regulation_practice] =
    useState({
      emotion_situation: "",
      emotion_resp: "",
      emotion_handle: "",
    });

  const [
    assigned_emotional_regulation_practice,
    assigned_setemotional_regulation_practice,
  ] = useState({
    emotion_situation: "",
    emotion_resp: "",
    emotion_handle: "",
  });
  const [checkBox, setCheckBox] = useState(false);

  const questionsData1 = [
    {
      question:
        "Your society wants to increase its online presence. Which goal is the most Specific?",
      options: [
        "Make our society more visible online.",
        "Increase Instagram followers by posting more content.",
        "Increase our Instagram followers by posting weekly event highlights.",
      ],
      correctAnswer:
        "Increase our Instagram followers by posting weekly event highlights.",
      explanation:
        "This goal specifies the platform (Instagram) and the content type (event highlights), as well as the frequency (weekly), allowing you to set clear targets.",
    },
    {
      question:
        "You want to grow the society’s membership. Which goal is the most Measurable?",
      options: [
        "Get more members involved.",
        "Increase membership by 50 new members by the end of the semester.",
        "Get a lot of new members to join.",
      ],
      correctAnswer:
        "Increase membership by 50 new members by the end of the semester.",
      explanation:
        "This goal includes a specific target number (50) and a timeline (end of the semester).",
    },

    {
      question:
        "Your society is planning a campus-wide charity event to raise funds for a local cause. Last year, you raised Rs. 20,000. Which goal seems Achievable?",
      options: [
        "Raise Rs. 150,000 for the charity event this year.",
        "Raise Rs. 75,000 for the charity event this year.",
        "Double our fundraising to Rs. 40,000 within two weeks.",
      ],
      correctAnswer: "Raise Rs. 75,000 for the charity event this year.",
      explanation:
        "This goal sets a target that’s slightly higher than last year, making it both challenging and realistic based on past performance.",
    },

    {
      question:
        "Your society is focused on environmental awareness. Which goal is the most Relevant?",
      options: [
        "Organize a game night for all students.",
        "Launch a monthly newsletter on environmental tips and issues.",
        "Get as many students as possible to attend society meetings.",
      ],
      correctAnswer:
        "Launch a monthly newsletter on environmental tips and issues.",
      explanation:
        "This aligns well with your society’s focus on environmental awareness and builds engagement with relevant content.",
    },

    {
      question:
        "Your society’s executive council wants to improve coordination and communication among different departments. Which goal is the most Time-Bound?",
      options: [
        "Hold regular meetings with department heads to improve communication.",
        "Schedule monthly check-in meetings with all department heads, starting next week.",
        "Plan to meet with department heads soon to discuss updates.",
      ],
      correctAnswer:
        "Schedule monthly check-in meetings with all department heads, starting next week.",
      explanation:
        "This goal includes a clear start time (next week) and sets a consistent frequency (monthly), making it easy to plan and track.",
    },
  ];

  const questionsData2 = [
    {
      question:
        'A goal that states "organize a minimum of 3 workshops this semester on any topic" is both Specific and Relevant in the SMART framework.',
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "While the goal is Specific, it may not be Relevant unless the workshops align with the society's mission or objectives.",
    },
    {
      question:
        'A KPI such as "increase social media followers by 200%" is always Achievable if the society uses effective marketing strategies.',
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Whether a KPI is Achievable depends on past growth rates, available resources, and audience size, not just strategy.",
    },
    {
      question:
        "A Time-bound goal that sets a deadline but does not specify milestones for intermediate progress is incomplete in the SMART framework.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Although a deadline is important, intermediate milestones help ensure consistent progress and accountability.",
    },
    {
      question:
        'The KPI "generate 1,000 impressions on social media posts for an event" directly measures event attendance and participation.',
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Impressions measure visibility and reach, not actual participation or attendance.",
    },
    {
      question:
        "To be considered Measurable, a goal must provide exact numbers for success but does not need to specify the method of measurement.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Measurable goals require both a target and a clear method to track progress or success.",
    },
  ];

  const [
    emotional_regulation_practice_feedback,
    setemotional_regulation_practice_feedback,
  ] = useState("");
  const [swotFeedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);

  const areEmotionSituationValid =
    countWords(emotional_regulation_practice?.emotion_situation) >= 50;
  const areEmotionalRespValid =
    countWords(emotional_regulation_practice?.emotion_resp) >= 50;
  const areEmotionalHandleValid =
    countWords(emotional_regulation_practice?.emotion_handle) >= 50;

  const isAnySectionInvalid =
    !areEmotionSituationValid ||
    !areEmotionalRespValid ||
    !areEmotionalHandleValid;

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config
      )
      .then((response) => {
        setDisablebtn(true);
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          // console.log('Week2 EM: ',response)

          // assigned_setemotional_regulation_practice({
          //   emotion_situation: data.emotion_situation,
          //   emotion_resp: data.emotion_resp,
          //   emotion_handle: data.emotion_handle,
          // });
          // assigned_setemotional_regulation_practice(response.data.emotional_regulation_practice_feedback);
        }
      });
  };

  const fetchAssignedReview2 = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config
      )
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });

        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          console.log("Week2 EM: ", response);

          setFeedback(response.data.feedback);

          assigned_setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
          // assigned_setemotional_regulation_practice(response.data.emotional_regulation_practice_feedback);
        }
      });
  };

  function countWords(text) {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    return words?.length;
  }

  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
        }
      });
  };

  console.log("Week2 FB: " + peerFeedback);

  const fetchStepData2 = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        // toast.success('Good Job! Activity Submitted', {
        //   icon: '👏',
        //   style: {
        //     borderRadius: '55px',
        //     marginBottom: '15px',
        //   },
        // });
        if (response.status === 200) {
          console.log("Data for peer2: ", response.data.content);
          let data = JSON.parse(response.data.content);

          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);
          setemotional_regulation_practice({
            emotion_situation: data.emotion_situation,
            emotion_resp: data.emotion_resp,
            emotion_handle: data.emotion_handle,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchStepData2(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
      fetchAssignedReview2(profileID, week_id, week_name, 5);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_3",
              },
              config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log("Cont", assigned_emotional_regulation_practice);

  console.log("FB" + emotional_regulation_practice_feedback);

  // const handleStepSubmission = async () => {
  //   console.log("this step", currentStep);

  //   try {
  //     if (status === "Active") {
  //       if (currentStep === 6) {
  //         await axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_feedback/`, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: 6,
  //           content: emotional_regulation_practice,
  //           pathway: 1,
  //           feedback: emotional_regulation_practice_feedback,
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Feedback Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting feedback:', error);

  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       } else if (currentStep === 5) {
  //         await axios.post(`${process.env.REACT_APP_API_KEY}/api/peer_submission/`, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: currentStep,
  //           content: emotional_regulation_practice,
  //           pathway: 1,
  //           status: "Reviewing",
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Activity Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting activity:', error);

  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       } else if (currentStep === 4) {
  //         await axios.post(${process.env.REACT_APP_API_KEY}/api/peer_submission/, {
  //           user_id: user.user_id,
  //           week_name: week_name,
  //           week_id: week_id,
  //           section: currentStep,
  //           content: swotSubmission,
  //           pathway: 1,
  //           status: "Reviewing",
  //         })
  //         .then(() => {
  //           toast.success('Good Job! Submission Submitted', {
  //             icon: '👏',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         })
  //         .catch((error) => {
  //           console.error('Error submitting submission:', error);

  //           toast.error('Oops! Something went wrong. Please try again later.', {
  //             icon: '❌',
  //             style: {
  //               borderRadius: '55px',
  //               marginBottom: '15px',
  //             },
  //           });
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error submitting data:', error);

  //     toast.error('Oops! Something went wrong. Please try again later.', {
  //       icon: '❌',
  //       style: {
  //         borderRadius: '55px',
  //         marginBottom: '15px',
  //       },
  //     });
  //   }
  // };
  const [subFeedback, setSubFeedback] = useState("");

  const handleSubFeedback = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`,
        {
          peerid: peerid,
          subFeedback: subFeedback,
          flag: true,
        },
        config
      )
      .then((res) => {
        // toast.success("Good Job! Feedback Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
      });
  };

  const [submit, setsubmit] = useState(false);

  const handleStepSubmission = () => {
    setsubmit(true);
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: emotional_regulation_practice,
            pathway: 1,
            status: "Reviewing",
            rating: 0,
            pr_enabled: true,
            activity_name: "Emotional Regulation Practice",
          },
          config
        )
        .then((response) => {
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        })
        .catch((error) => {
          console.error("Error submitting activity:", error);
          // Handle the error, you can show an error message using toast or any other method.
        });
    }
  };
  const [isShown, setIsShown] = useState(false);
  const handleRating = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_rating/`,
        {
          peerid: peerid,
          rating: rating,
        },
        config
      )
      .then((res) => {
        // toast.success("Good Job! Rating Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
      });
  };
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const totalSteps = 5; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const accordionData = [
    {
      title: "Utilize",
      content:
        "How does Bilal utilize Emotional Intelligence in his role as a student leader?",
    },
    {
      title: "Lesson",
      content:
        "What can we learn from Bilal's journey that can enhance our leadership experience?",
    },
  ];

  const accordionData3 = [
    {
      title: "Emotional Intelligence",
      content:
        "This week, we deepened our understanding of emotional intelligence and its fundamental role in effective leadership. We explored the power of emotional self-awareness, self-regulation, empathy, and emotional agility through Bilal's continued leadership journey.",
    },
    {
      title: "Aspects",
      content:
        "We explored the dimensions of emotional intelligence, including understanding our emotions, managing emotions effectively, recognizing others' emotions, and managing relationships. These aspects are critical for building emotional intelligence.",
    },
    // {
    //   title: 'Quiz',
    //   content: 'You took an Emotional Intelligence Quiz to assess your emotional awareness, understanding, self-regulation, and relationship management. This activity helped you identify your strengths and areas of growth in emotional intelligence.'
    // },
    {
      title: "Regulation Practice",
      content:
        "You reflected on an emotional experience from your day, documenting the situation, your emotional response, and how you handled it. This practice was designed to enhance your emotional self-awareness and regulation skills.",
    },
    // {
    //   title: 'Peer Review',
    //   content: 'You provided valuable feedback to your peers on their emotional intelligence quiz responses and emotional regulation practices. Your thoughtful insights and constructive feedback have contributed to a supportive learning environment and have supported their journey in emotional intelligence development.'
    // },
  ];

  const resources = [
    {
      title: "Resource 1",
      content:
        'Explore "Emotional Intelligence 2.0" by Travis Bradberry to delve deeper into the principles and practices of emotional intelligence.',
    },
    {
      title: "Resource 2",
      content:
        'Read Chapter 6 – "The Emotional Machine" in "Thinking, Fast and Slow" by Daniel Kahneman for insights into the interplay between emotions and decision-making.',
    },
    {
      title: "Resource 3",
      content:
        'Discover "The Power of Self-Awareness" by Dr. Tasha Eurich, a resource that provides valuable perspectives on enhancing self-awareness for effective leadership.',
    },
    {
      title: "Resource 4",
      content:
        'Explore "Why Aren’t We More Compassionate?" by Daniel Goleman to gain insights into the factors influencing compassion and empathy in leadership.',
    },
  ];

  const accordionData2 = [
    {
      title: "Self-awareness",
      sub: "Illuminating Your Emotional Landscape",
      content:
        "Self-awareness, the foundation of EI, helps us recognize and understand our own emotions. It allows us to perceive how our emotions influence our actions, decisions, and interactions with others.\n\n" +
        "\nPoint of Action:\n\n• Reflect on your emotions and how they influence your actions\n" +
        "• Identify the triggers that elicit strong emotional responses in you.\n" +
        "• Examine the relationship between your emotions and your values.",
    },
    {
      title: "Self-regulation",
      sub: "Mastering the Art of Emotional Balance",
      content:
        "Self-regulation is about managing and controlling our emotions effectively, which is crucial for maintaining emotional balance and resilience.\n\n" +
        "\nPoint of Action:\n\n• Develop strategies that help you manage your emotions and maintain equilibrium, especially in stressful situations\n" +
        "• Reflect on instances where self-regulation has made a difference in your leadership journey.",
    },
    {
      title: "Motivation",
      sub: "Fuelling Your Leadership Journey",
      content:
        "Motivation, a key aspect of EI, is about harnessing our emotions to drive us towards our goals, enhancing our leadership effectiveness.\n\n" +
        "\nPoint of Action:\n\n• Identify your core motivations and how they align with your leadership goals.\n" +
        "• Reflect on how your emotions can fuel your motivation and influence your actions.",
    },
    {
      title: "Empathy",
      sub: "Building Bridges of Understanding",
      content:
        "Empathy allows us to understand and share the feelings of others. It's a powerful tool for building strong and authentic relationships.\n\n" +
        "\nPoint of Action:\n\n• Practice active listening to understand others' perspectives and emotions.\n" +
        "• Reflect on how empathy can enhance your leadership style and relationships within your team.",
    },
    {
      title: "Social Skills",
      sub: "Crafting Connections with Emotional Intelligence",
      content:
        "Social skills involve effectively managing relationships and inspiring others. They enable us to connect with our team members and the wider community.\n\n" +
        "\nPoint of Action:\n\n• Consider how you use EI in your interactions and communications with your team.\n" +
        "• Reflect on situations where EI has helped you navigate conflicts or challenges.",
    },
  ];

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  const [RecheckBox, setRecheckBox] = useState(false);
  const [RecheckBox2, setRecheckBox2] = useState(false);

  useEffect(() => {
    if (currentStep === 0 || currentStep === 1 || currentStep === 3) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 2 && currentImageIndex < 4) {
      setNextBtnCondtion(false);
    } else if (currentStep === 2 && currentImageIndex > 4) {
      setNextBtnCondtion(true);
    } else if (currentStep === 4 && status === "Active") {
      if (submit) {
        setNextBtnCondtion(true);
      } else {
        setNextBtnCondtion(false);
      }
    } else if (currentStep === 4 && status === "Completed") {
      setNextBtnCondtion(true);
    }

    // else if (status==='Completed' && Object.values(swotSubmission).every(value => value !== '')){
    //   setNextBtnCondtion(true);
    // }

    // else if (currentStep === 5 && (disableBtnTwo || (status==='Completed' && Object.values(swotSubmission2).some(value => value !== '')))) {
    //   setNextBtnCondtion(true);
    // }
    else {
      setNextBtnCondtion(false);
    }
  }, [currentStep, currentImageIndex, status, submit]);

  const steps = [
<section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
           
Welcome to Week 3: SMART Goals and KPI Development
      </h2>
      <p className="mt-4 text-gray-600">
       Ever felt like your society’s goals are too vague or hard to track? This week, we’ll fix that! You’ll learn to set clear, actionable goals using the SMART framework and define key performance indicators (KPIs) to measure success. By mastering these tools, you’ll be able to prioritize effectively and ensure that your society achieves its objectives with confidence. 
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >

 <Quote content={"Teams with clear, measurable goals are 70% more likely to meet or exceed their targets."} person={""}/>

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                clqssName="w-11/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736107726/rafiki_axwlze.png"
              />
            </div>
          </div>
        </div>
      </div>

      </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Defining SMART Goals</b>
      </h2>
      <div className="mt-6 flex justify-start w-full">
        <SliderWeek2
          className="w-full"
          onImageIndexChange={handleImageIndexChange}
        />
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        SMART Goals Introduction Quiz
      </h2>
      <p className="mt-4 text-gray-600">
        <b>Instructions:</b> You have been given a set of 5 vague goals, and you
        are required to transform these into SMART goals by selecting options
        that make them more Specific, Measurable, Achievable, Relevant, and
        Time-bound. Instant feedback will help you understand why each choice
        matters.
      </p>
      <QuizComponent questionsData={questionsData1} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <p class="text-base">
        Congratulations on completing the SMART Goals quiz!
      </p>
      <p class="mt-2 text-base">
        You’ve learned how to make goals Specific, Measurable, Achievable,
        Relevant, and Time-bound. Using the SMART framework helps create clear,
        actionable goals that you can measure and achieve.
      </p>
      <h2 class="mt-2 text-lg font-bold">Takeaway Tip:</h2>
      <p class="mt-2">
        As you set goals for your society, always ask yourself:
      </p>
      <ul class="mt-2 list-disc list-inside">
        <li>Is it clear what we want to accomplish?</li>
        <li>Can we mjueasure our progress?</li>
        <li>Is this realistic given our resources?</li>
        <li>Does this goal align with our mission?</li>
        <li>Do we have a deadline to keep us on track?</li>
      </ul>
    </section>,



<section className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24" style={{ height: "100%" }}>
  <div className="flex justify-center">
    <div className="w-full max-w-4xl h-[500px]">
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe 
          src="https://player.vimeo.com/video/1069744683?h=3fd54952c3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
          frameBorder="0" 
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} 
          title="Week 3"
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  </div>
</section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 2:</b> True/False Questions
      </h2>

      <QuizComponent questionsData={questionsData2} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)} />
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Defining SMART Goals";

      case 2:
        return "SMART Goals Introduction Quiz";

      case 3:
        return "Takeaway Tip";

      case 4:
        return "KPI Development";

   

      default:
        return "Activity 2";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl ">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto ">
                <div className="w-full bg-white rounded-full ">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary flex flex-col md:flex-row shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex flex-row gap-2">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              //       disabled={
                              //           currentStep === 2 && (currentImageIndex<5) ||
                              //           isAnySectionInvalid &&  currentStep === 4

                              // }
                              disabled={
                                
                                [2,5].includes(currentStep) && !isOptionSelected}

                              // disabled={!nextBtnCondtion}
                            >  
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={[5].includes(currentStep) && !isOptionSelected}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week3;
