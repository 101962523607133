import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import SliderWeek4 from "./SliderWeek4";
import Vimeo from "@u-wave/react-vimeo";
import Collapse from "../components/Collapse";
import Confetti from "react-confetti";
import "./new-certi.css";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";

const Week5 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 5;
  const week_name = "week_4";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const [peerFeedback, setpeerFeedback] = useState("");
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [RecheckBox, setRecheckBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [empathyMapping, setempathyMapping] = useState({
    think: "",
    feel: "",
    say: "",
    hear: "",
    see: "",
  });
  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
    saying: "",
  });
  const [subFeedback, setSubFeedback] = useState("");
  const handleSubFeedback = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_sub_feedback/`,
        {
          peerid: peerid,
          subFeedback: subFeedback,
          flag: true,
        },
        config,
      )
      .then((res) => {
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  function countWords(text) {
    if (!text) return 0;
    const trimmedText = text.trim();
    if (trimmedText === '') return 0;
    const words = trimmedText.split(/\s+/);
    return words.length;
  }

  const areStrengthsValid = countWords(empathyMapping?.think) >= 50;
  const areWeaknessesValid = countWords(empathyMapping?.feel) >= 50;
  const areOpportunitiesValid = countWords(empathyMapping?.hear) >= 50;
  const areThreatsValid = countWords(empathyMapping?.see) >= 50;
  const areSayingValid = countWords(empathyMapping?.say) >= 50;

  const isAnySectionInvalid =
    !areStrengthsValid ||
    !areWeaknessesValid ||
    !areOpportunitiesValid ||
    !areThreatsValid ||
    areSayingValid;

  const isEveryFieldValid = Object.values(empathyMapping).every((value) => {
    // Check if the value exists and its length is less than 50 characters
    return value && value.length >= 50;
  });

  const [VideoSubmission, setVideoSubmission] = useState({
    video: "",
  });
  const [assignedVideoSubmission, setassignedVideoSubmission] = useState({
    video: "",
  });
  const [swotFeedback, setFeedback] = useState("");
  const [videoFeedback, setvideoFeedback] = useState("");
  const [videoFeedbackUser, setvideoFeedbackUser] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config,
      )
      .then((response) => {
        console.log("data getted", response);
        // toast.success("Good Job! Activity Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            // saying: data.saying,
            // video:data.video,

            think: data.think,
            feel: data.feel,
            say: data.say,
            hear: data.hear,
            see: data.see,
          });
          setFeedback(response.data.feedback);
          setvideoFeedback(response.data.feedback);
        }
      });
  };

  const fetchAssignedVideo = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config,
      )
      .then((response) => {
        console.log("VIDEO: ", response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setassignedVideoSubmission({
            video: data.video,
          });
          setvideoFeedback(response.data.feedback);
        }
      });
  };
  const [peerid, setPeerId] = useState(0);
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);

          setempathyMapping({
            think: data.think,
            feel: data.feel,
            say: data.say,
            hear: data.hear,
            see: data.see,
          });
        }
      });
  };

  const fetchVideoStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setpeerFeedback(response.data.feedback);
          setPeerId(response.data.id);
          setVideoSubmission({
            video: data.video,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchVideoStepData(profileID, week_id, week_name, 5, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
      fetchAssignedVideo(profileID, week_id, week_name, 5);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "898993100"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };
  const [rating, setRating] = useState(0);
  const handleRating = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/peer_rating/`,
        {
          peerid: peerid,
          rating: rating,
        },
        config,
      )
      .then((res) => {
        toast.success("Good Job! Rating Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };
  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_5",
              },
              config,
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(empathyMapping);

  console.log(empathyMapping);
  console.log(swotFeedback);

  const accordionData = [
    {
      title: "The Dimensions of Empathy",
      content: (
        <ul className="px-6" style={{ listStyle: "disc" }}>
          <li>
            <strong>Cognitive Empathy:</strong> This refers to our ability to
            understand or comprehend another person's perspective or thoughts.
            It's like reading someone's mind, discerning their feelings, and
            understanding their reactions.
          </li>
          <li>
            <strong>Emotional Empathy:</strong> This is about feeling alongside
            another person. When they rejoice, you rejoice. When they grieve,
            you feel their sorrow. It's an emotional resonance, a shared
            feeling.
          </li>
          <li>
            <strong>Compassionate Empathy:</strong> This dimension propels us
            into action. It's not just understanding or feeling what another
            person is going through but being moved to help if needed.
          </li>
        </ul>
      ),
    },
    {
      title: "Why is Empathy Crucial for Leaders?",
      content: (
        <ul className="px-6" style={{ listStyle: "disc" }}>
          <li>
            <strong>Builds Trust:</strong> When team members feel understood and
            valued, it fosters an environment of trust. They are more likely to
            open up, share concerns, and contribute actively.
          </li>
          <li>
            <strong>Enhances Collaboration:</strong> Understanding diverse
            perspectives leads to richer brainstorming sessions and more
            holistic solutions.
          </li>
          <li>
            <strong>Promotes Inclusivity:</strong> An empathetic leader ensures
            that every voice is heard, fostering a culture where everyone feels
            they belong.
          </li>
          <li>
            <strong>Drives Engagement:</strong> Employees are more committed and
            motivated when they feel their leaders understand and care about
            them.
          </li>
          <li>
            <strong>Facilitates Conflict Resolution:</strong> Empathy allows
            leaders to see all sides of an issue, mediating disputes with
            fairness and understanding.
          </li>
        </ul>
      ),
    },
  ];

  const accordionData2 = [
    {
      title: "Understanding Others' Perspectives",
      sub: "Walking in Another's Shoes",
      contentTwo: [
        "Empathy begins with the willingness to step into another's world. It's about setting aside our judgments and truly understanding another's perspective.",
        "Challenge yourself to listen actively, without formulating responses in your mind.",
        "Engage in conversations with an open heart, seeking to understand before being understood.",
        "Reflect on moments when you felt deeply understood by someone. What made those interactions special?",
      ],
      content:
        "Empathy begins with the willingness to step into another's world. It's about setting aside our judgments and truly understanding another's perspective.\n\n" +
        "• Challenge yourself to listen actively, without formulating responses in your mind.\n" +
        "• Engage in conversations with an open heart, seeking to understand before being understood.\n" +
        "• Reflect on moments when you felt deeply understood by someone. What made those interactions special?",
    },
    {
      title: "Empathetic Communication",
      sub: "Building Bridges of Understanding",
      contentTwo: [
        "Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.",
        'Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."',
        "Reflect on your communication style. How can you infuse more empathy into your interactions?",
      ],
      content:
        "Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.\n\n" +
        "• Practice active listening, giving your full attention to the speaker.\n" +
        '• Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."\n' +
        "• Reflect on your communication style. How can you infuse more empathy into your interactions?",
    },
    {
      title: "Empathy in Action",
      sub: "Moving Beyond Feeling to Helping",
      contentTwo: [
        "Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.",
        "Identify opportunities where you can offer support or assistance based on your empathetic understanding",
        "Engage in acts of kindness, big or small, driven by genuine empathy.",
        "Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?",
      ],
      content:
        "Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.\n\n" +
        "• Identify opportunities where you can offer support or assistance based on your empathetic understanding.\n" +
        "• Engage in acts of kindness, big or small, driven by genuine empathy.\n" +
        "• Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?",
    },
  ];

  const accordionData3 = [
    {
      title: "Understanding Empathy",
      content:
        "We differentiated empathy from sympathy and compassion, emphasizing its role as a bridge to genuine human connection.\n\n",
    },
    {
      title: "Empathy Mapping Activity",
      content:
        "By stepping into Ahmed's world, we practiced the art of empathy, gaining insights into the myriad emotions, thoughts, and perceptions one might experience in a new environment.\n\n",
    },
    {
      title: "Peer Reviews",
      content:
        "Your thoughtful feedback and reflections will showcase a deep understanding of empathy's role in leadership, fostering a collaborative and supportive learning environment.\n\n",
    },
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleStepSubmission = () => {
    console.log("this step", currentStep);

    if (status === "Active") {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: { reflection: swotSubmission.response },
            pathway: 1,
            status: "Reviewing",
            pr_enabled: true,
            rating: 0,
            activity_name: "Discussion Forum Response"
          },
          config,
        )
        .then(() => {
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.error("Error submitting activity:", error);
          toast.error("Oops! Something went wrong. Please try again later.", {
            icon: "❌",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        });
    }
  };

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };


  const questionsData4 = [
    {
      question: "In the Public Recognition case study, why is public recognition effective?",
      options: [
        "It motivates the individual and shows others the value of their work.",
        "It saves time.",
        "It’s a tradition.",
      ],
      correctAnswer: "It motivates the individual and shows others the value of their work.",
      explanation:
        "Public recognition highlights individual contributions, motivating the person recognized and inspiring the team to perform better.",
    },
    {
      question:
        "Based on the Personal Thank-Yous case study, what’s the primary benefit of giving a personal thank-you?",
      options: [
        "It allows leaders to give detailed instructions.",
        "It makes team members feel valued and appreciated on a personal level.",
        "It reminds members of their tasks.",
      ],
      correctAnswer:
        "It makes team members feel valued and appreciated on a personal level.",
      explanation:
        "Personal thank-yous strengthen individual relationships, showing appreciation in a meaningful and direct way.",
    },
    {
      question:
        "In the Celebrating Small Wins case study, what’s the main reason for celebrating milestones?",
      options: [
        "To mark the completion of a project.",
        "To boost morale and acknowledge progress, not just the final result.",
        "To make meetings more fun.",
      ],
      correctAnswer:
        "To boost morale and acknowledge progress, not just the final result.",
      explanation:
        "Celebrating milestones helps maintain motivation, energizes the team, and reinforces a culture of recognition.",
    },
  ];

  const questionsData = [
    {
      question: "What is the role of effective communication in leadership?",
      options: [
        "To inform the team about tasks",
        "To convey expectations and give instructions",
        "To connect, inspire, and foster a shared understanding",
        "To command and control the team",
      ],
      correctAnswer: "To connect, inspire, and foster a shared understanding",
      explanation:
        "While A, B, and D may be parts of communication, the broader role of effective communication in leadership is to connect with the team, inspire them towards shared goals, and foster a mutual understanding (Option C).",
    },
    {
      question:
        "Which of the following is NOT a common challenge in effective communication?",
      options: [
        "Overlooking non-verbal communication",
        "Listening more than talking",
        "Misunderstanding communication as just talking",
        "Not adapting communication style to suit different situations",
      ],
      correctAnswer: " Listening more than talking",
      explanation:
        "Active listening is a crucial aspect of effective communication. Therefore, listening more than talking is not a challenge but rather a positive practice in effective communication (Option B).",
    },
    {
      question:
        "How does setting clear intentions before communicating help a leader?",
      options: [
        "It ensures that the leader dominates the conversation",
        " It ensures that the message is impactful and purposeful",
        "It ensures that the leader talks more than others",
        "It ensures that the leader does not have to listen to others",
      ],
      correctAnswer: " It ensures that the message is impactful and purposeful",
      explanation:
        "Clear intentions help the leader guide the communication in a purposeful and meaningful way, ensuring their message carries the necessary impact (Option B).",
    },
    {
      question: "What role does 'why' play in effective communication?",
      options: [
        "It complicates the message",
        " It adds unnecessary details",
        "It provides the emotional core of the message",
        "It diverts attention from the main message",
      ],
      correctAnswer: "It provides the emotional core of the message",
      explanation:
        "The 'why' in communication refers to the purpose, motivation, or reason behind a message or action. It provides the emotional core that resonates with people and makes the message more meaningful (Option C).",
    },
    {
      question:
        "According to Karim's story, what transformed the debate team's arguments into compelling narratives?",
      options: [
        "They used complicated jargon",
        " They spoke louder than their opponents",
        "They focused on personal connections and broader themes",
        "They rehearsed more times than their opponents",
      ],
      correctAnswer: "They focused on personal connections and broader themes",
      explanation:
        "In Karim's story, the debate team's arguments became compelling narratives when they connected their arguments to broader themes and personalized them. This made the arguments emotionally resonate with the audience (Option C).",
    },
  ];

  const books = [
    "Crucial Conversations: Tools for Talking when Stakes Are High by Kerry Patterson, Chapter 7 – How to Transform Anger and Hurt Feelings into Powerful Dialogue",
    "Business Communication: Developing Leaders for a Networked World by Peter Cardon, Chapter 4 – Crafting Messages for Electronic Media",
    "Just Listen by Mark Goulston, Chapter 4 – The Power of Listening",
    "Interpersonal Communication by Sarah Trenholm and Arthur Jensen, Chapter 7 – The Skills of Listening",
    "10 Ways to Have a Better Conversation by Celeste Headlee",
  ];
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  //   const [currentQuestion, setCurrentQuestion] = useState(0);
  //   const [selectedOption, setSelectedOption] = useState('');
  //   const [score, setScore] = useState(0);

  //   const handleOptionSelect = (option) => {
  //     setSelectedOption(option);
  //   };

  //   const handleNextQuestion = () => {
  //     if (selectedOption === questionsData[currentQuestion].correctAnswer) {
  //       setScore(score + 1);
  //     }
  //     setSelectedOption('');
  //     setCurrentQuestion(currentQuestion + 1);
  //   };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736588795/Slide_16_9_-_105_rteu6m.png", // Replace with your image URL
  ];

  const questionsData1 = [
    {
      question:
        "The President of a society delegates tasks for an upcoming event but does not specify deadlines. This leads to confusion among team members. What step did the President miss?",
      options: ["Align", "Define", "Follow Up", "Motivate"],
      correctAnswer: "Define",
      explanation:
        "Clearly defining goals, responsibilities, and deadlines is essential to prevent misunderstandings and set a strong foundation for accountability.",
    },
    {
      question:
        "A Vice President holds a meeting to discuss team goals. One team member points out that they need more clarity on their responsibilities. What should the VP do next?",
      options: [
        "Assign the task immediately",
        "Define the goals and responsibilities clearly",
        "Ignore the concern",
        "Set stricter deadlines",
      ],
      correctAnswer: "Define the goals and responsibilities clearly",
      explanation:
        "When a team member expresses confusion, the leader should revisit and clearly articulate the goals and responsibilities to ensure alignment.",
    },

    {
      question:
        "During a feedback session, the leader of the Drama Society realizes that team members feel left out of decision-making. What step should the leader focus on next?",
      options: ["Align", "Define", "Follow Up", "Motivate"],
      correctAnswer: "Align",
      explanation:
        "Aligning ensures that team members feel their input is valued, fostering motivation and commitment to team goals.",
    },

    {
      question:
        "If a team member misses multiple deadlines, what is the best initial approach?",
      options: [
        "Penalize them immediately",
        "Follow up to understand the reasons behind the delays",
        "Reassign their tasks to someone else",
        "Ignore the issue",
      ],
      correctAnswer: "Follow up to understand the reasons behind the delays",
      explanation:
        "Addressing underperformance begins with understanding the causes. Following up shows empathy and provides insight into how to address the issue effectively.",
    },

    {
      question:
        "A project leader sets weekly check-ins to track progress and address obstacles. This approach emphasizes which step?",
      options: ["Align", "Define", "Follow Up", "Motivate"],
      correctAnswer: "Follow Up",
      explanation:
        "Regular follow-ups ensure accountability, provide opportunities for feedback, and keep the team on track toward goals.",
    },
  ];

  const questionsData2 = [
    {
      question:
        "Addressing underperformance always requires disciplinary action.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Correct! Not all underperformance requires discipline. Often, understanding the root cause and providing support or realignment of tasks can solve the issue.",
    },
    {
      question:
        "Understanding the causes of underperformance can help create an effective solution.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Correct! Identifying causes allows leaders to address the specific challenges a team member is facing, whether it’s a skill gap, motivation issue, or personal challenge.",
    },
    {
      question:
        "Regular check-ins are unnecessary once a team member shows improvement.",
      options: ["True", "False"],
      correctAnswer: "False",
      explanation:
        "Correct! Regular check-ins ensure sustained progress and help identify any new challenges early. Consistency in follow-ups shows a leader’s commitment to team success.",
    },
    {
      question:
        "Aligning tasks with a team member’s interests can improve their engagement.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Correct! When tasks align with a team member’s interests, they are more likely to feel motivated and contribute effectively.",
    },
    {
      question:
        "Clear communication of expectations is crucial to addressing underperformance.",
      options: ["True", "False"],
      correctAnswer: "True",
      explanation:
        "Correct! Clear communication ensures that team members understand their responsibilities and what is expected of them, preventing confusion or misalignment.",
    },
  ];

  const questionsData3 = [
    {
      question:
        "In the “Recognition in Public” video, why is public recognition effective?",
      options: [
        "It motivates the individual and shows others the value of their work.",
        "It saves time.",
        "It’s a tradition.",
      ],
      correctAnswer:
        "It motivates the individual and shows others the value of their work.",
    },
    {
      question:
        "According to the “Personal Thank-Yous” video, what’s the primary benefit of a personal thank-you?",
      options: [
        "It allows leaders to give detailed instructions.",
        "It makes team members feel valued and appreciated on a personal level.",
        "It reminds members of their tasks.",
      ],
      correctAnswer:
        "It makes team members feel valued and appreciated on a personal level.",
    },

    {
      question:
        "In the “Celebrating Small Wins” video, what’s the main reason for celebrating milestones?",
      options: [
        "To mark the completion of a project.",
        "To boost morale and acknowledge progress, not just the final result.",
        "To make meetings more fun.",
      ],
      correctAnswer:
        "To boost morale and acknowledge progress, not just the final result.",
    },
  ];

  const [swotSubmission, setSubmission] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 5: Building and Maintaining High Performance teams
      </h2>
      <p className="mt-4 text-gray-600">
        Strong teams are the backbone of successful societies. This week, we’ll
        focus on building trust, collaboration, and motivation within your team.
        From practical exercises to handling underperformance, you’ll learn how
        to set expectations, recognize achievements, and create a culture where
        every member thrives.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Conflicts in student societies often arise due to unclear roles; defining responsibilities reduces misunderstandings."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                clqssName="w-11/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736496939/rafiki_it27mo.png"
              />
            </div>
          </div>
        </div>
      </div>
     </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 1:</b> Setting Clear Expectations and Accountability
      </h2>

      <div className="mt-4">
        <div className="image-gallery flex justify-center items-center">
          <img
            src={images[currentImage]}
            alt={`Gallery Image ${currentImage + 1}`}
            className="rounded-lg shadow-lg"
            style={{ maxHeight: "800px", maxWidth: "100%" }}
          />
        </div>
      </div>

      <p class="text-base mt-6">
        To ensure a team operates smoothly and every member knows their role,
        it’s crucial to set clear, actionable expectations. Here’s how:
      </p>
      <ol class="mt-4 list-decimal list-inside">
        <li class="mt-2">
          <strong>Define</strong>
          <p class="mt-1">
            Start by clarifying goals and responsibilities. Be specific about
            what needs to be done, by whom, and by when. Clear definitions
            prevent misunderstandings and set a solid foundation for
            accountability.
          </p>
        </li>
        <li class="mt-4">
          <strong>Align</strong>
          <p class="mt-1">
            Once expectations are defined, align with your team to gain their
            agreement and commitment. Discuss any concerns or suggestions and
            adjust as needed. When team members feel their input is valued,
            they’re more likely to be motivated and accountable.
          </p>
        </li>
        <li class="mt-4">
          <strong>Follow Up</strong>
          <p class="mt-1">
            Accountability requires consistent follow-up. Set up regular
            check-ins or feedback loops to discuss progress, address obstacles,
            and celebrate small wins. Following up shows your commitment to team
            success and gives members a chance to adjust if needed.
          </p>
        </li>
      </ol>
      <p class="mt-6 font-semibold">
        <strong>Key Takeaway:</strong> By defining, aligning, and following up
        on expectations, you build a clear path for accountability, allowing
        each team member to feel supported and motivated to meet their goals!
      </p>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> Scenario Based MCQs
      </h2>

      <QuizComponent
        questionsData={questionsData1}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,



<section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
  <h2 className="text-xl font-medium sm:text-xl mb-6">
    Addressing underperformance in Teams
  </h2>

  <div className="flex justify-center">
    <div className="w-full max-w-4xl h-[500px]">
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe 
          src="https://player.vimeo.com/video/1069754604?h=4f4059ceeb&badge=0&autopause=0&player_id=0&app_id=58479/embed" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowFullScreen 
          frameBorder="0" 
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
        />
      </div>
    </div>
  </div>
</section>,


    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 2:</b> True/False Questions
      </h2>

      <QuizComponent
        questionsData={questionsData2}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,
    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 2:</b> Self Assessment
      </h2>

      <h2 class="text-lg font-bold">Instructions:</h2>
      <p class="mt-2">
        Read the below prompt carefully, and take a few minutes to think about
        your answer before responding the prompt.
      </p>
      <h3 class="mt-4 text-lg font-bold">Prompt:</h3>
      <p class="mt-2">
        Reflect on a time you trusted or distrusted someone in a team setting.
        What were the outcomes? How did it affect your motivation and
        performance?
      </p>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) =>
              setSubmission((prev) => ({
                ...prev,
                response: e.target.value,
              }))
            }
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <div className="alert alert-info flex flex-col justify-start text-start items-start text-sm gap-1 mt-2">  <b>Just so you know</b> Your progress saves automatically. The numbers might not change, but we've got you covered!  </div>

      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission}
        disabled={status === "Completed" || !swotSubmission.response || countWords(swotSubmission.response) < 10}
      >
        Submit
      </button>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 3:</b> Reflection Questions
      </h2>

      <QuizComponent
        questionsData={questionsData3}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,


    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">Scenario-Based Case Study for Motivation and Recognition</h2>

      <div className="mt-4">
        <h3 className="font-bold">Case Study 1: Public Recognition</h3>
        <p>
          <strong>Setting:</strong> Rizq Society recently held a successful food
          drive, surpassing their donation goals by 20%.
        </p>
        <p>
          <strong>Story:</strong> Fatima, the society president, starts the
          weekly meeting by congratulating the team. She specifically
          highlights Amina’s role in organizing logistics, which ensured smooth
          operations.
        </p>
        <p>
          <strong>Outcome:</strong> Amina feels proud and motivated, and the
          rest of the team is inspired to perform at their best, knowing their
          efforts will be recognized.
        </p>

        <h3 className="font-bold mt-6">Case Study 2: Personal Thank-Yous</h3>
        <p>
          <strong>Setting:</strong> Hum Aahang Society just hosted their annual
          musical evening. Bilal stayed late to clean up after the event.
        </p>
        <p>
          <strong>Story:</strong> Sana, the society president, approaches Bilal
          the next day to personally thank him for his effort. She acknowledges
          his dedication and explains how his actions positively impacted the
          event’s success.
        </p>
        <p>
          <strong>Outcome:</strong> Bilal feels valued and expresses interest in
          taking on more responsibilities in future events.
        </p>

        <h3 className="font-bold mt-6">Case Study 3: Celebrating Small Wins</h3>
        <p>
          <strong>Setting:</strong> INDEX Society completed the first phase of
          their business plan competition, receiving strong feedback from
          participants.
        </p>
        <p>
          <strong>Story:</strong> Zain, the vice president, gathers the team
          and organizes a small celebration with snacks and a “Great Job Team”
          banner. He emphasizes how each member contributed to achieving this
          milestone.
        </p>
        <p>
          <strong>Outcome:</strong> The team feels energized and more committed
          to working toward the final phase of the competition.
        </p>

        <h3 className="font-bold mt-6">Key Takeaways:</h3>
        <ul className="list-disc pl-6">
          <li>Recognition, both public and personal, fosters a positive team culture.</li>
          <li>Celebrating milestones maintains motivation and acknowledges progress.</li>
          <li>
            Tailoring recognition to individual preferences and team dynamics amplifies its
            impact.
          </li>
        </ul>
      </div>
    </section>,

    // Step 2: Reflection Questions
    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">Reflection Questions</h2>

      <QuizComponent
        questionsData={questionsData4}
        onOptionSelected={(isSelected) => setIsOptionSelected(true)}
      />
    </section>,


    // <section
    //   className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    //   style={{ height: "100%" }}
    // >
    //   <div className="mt-6 flex justify-start w-full">
    //     <SliderWeek4
    //       className="w-full"
    //       onImageIndexChange={handleImageIndexChange}
    //     />

    //   </div>
    // </section>,

    //     <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
    //     {/* Step 1 content */}
    //     <h2 className="text-xl font-medium sm:text-xl">
    //       Empathy & Leadership
    //     </h2>

    //     <div className="mt-6 flex justify-center w-full h-full">
    //       <div
    //         style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
    //       >
    //         <div className="video-container">
    //           {/* <YouTube videoId={videoId} opts={opts} /> */}
    //           <Vimeo
    //     video={videoId}
    //     autoplay
    //   />

    //         </div>
    //       </div>
    //     </div>
    //   </section>,

    //     <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //       {/* Step 1 content */}

    //       <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //         <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
    //           <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //             <img
    //               className="w-full"
    //               src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736588795/Slide_16_9_-_105_rteu6m.png"
    //             />
    //           </div>

    //           <div className="">
    //             <h2 className="text-xl font-medium sm:text-xl">
    //               <b>Understanding Empathy: The Soul of Connection</b>
    //             </h2>
    //             <p className="mt-4 text-gray-600">
    //               Empathy, often misconstrued as mere sympathy or compassion, stands
    //               distinct in its depth and application. At its core, empathy is the
    //               ability to truly understand and share the feelings of another, not
    //               merely as an observer but as if you were in their shoes. It's the
    //               bridge that connects individuals, transcending barriers of
    //               language, culture, and experience.
    //             </p>

    //             <div className="mt-6 gap-6">
    //               <div className="space-y-4">

    //               <Collapse contentTwo="contentTwo" accordionData={accordionData}/>

    //                 {/* <p className="p-2">

    //                 </p> */}

    //               </div>
    //             </div>

    //             <p className="bg-gray-100 mt-8 p-4 rounded-xl font-italic text-center">
    //           {" "}
    //           <strong>Note: </strong>In the realm of leadership, empathy is not just a
    //                   'nice-to-have' but a 'must-have.' It's the soul of connection,
    //                   the essence of understanding, and the foundation of genuine
    //                   collaboration. In a world that's increasingly interconnected
    //                   yet divided, empathy stands out as a beacon of hope, reminding
    //                   us of our shared humanity and the ties that bind us all.
    //         </p>
    //           </div>
    //         </div>
    //       </div>
    //     </section>,

    //     <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //       {/* Step 1 content */}

    //       <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //         <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
    //           <div className="relative h-20 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
    //             <img
    //               className="w-full"
    //               src="https://res.cloudinary.com/ahbranding/image/upload/v1701427247/AHbranding/Group_34784452_hduqki.png"
    //             />
    //           </div>

    //           <div className="">
    //             <h2 className="text-xl font-medium sm:text-xl">
    //               <b>Core Aspects of Empathy</b>
    //             </h2>
    //             <p className="mt-4 text-gray-600">

    //               In our pursuit of leadership excellence, empathy emerges as a
    //               pivotal quality. By nurturing empathy, we foster trust, enhance
    //               collaboration, and build teams that are united in purpose and
    //               spirit. Dive into the Core Aspects of Empathy:
    //             </p>

    //             <div className="mt-6">
    //               <div className="container flex flex-col justify-center mx-auto px-0 ">
    //                 <div className="space-y-4">

    //                 <Collapse contentTwo="contentTwo"  accordionData={accordionData2}/>
    //                   {/* <details className="w-full border rounded-lg">
    //                     <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
    //                       Understanding Others' Perspectives
    //                     </summary>
    //                     <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
    //                       Walking in Another's Shoes
    //                     </h3>
    //                     <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
    //                       Empathy begins with the willingness to step into another's
    //                       world. It's about setting aside our judgments and truly
    //                       understanding another's perspective.
    //                     </p>
    //                     <ul
    //                       className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
    //                       style={{ listStyle: "circle" }}
    //                     >
    //                       <li>
    //                         Challenge yourself to listen actively, without
    //                         formulating responses in your mind.
    //                       </li>
    //                       <li>
    //                         Engage in conversations with an open heart, seeking to
    //                         understand before being understood.
    //                       </li>
    //                       <li>
    //                         Reflect on moments when you felt deeply understood by
    //                         someone. What made those interactions special?
    //                       </li>
    //                     </ul>
    //                   </details>

    //                   <details className="w-full border rounded-lg">
    //                     <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
    //                     Empathetic Communication                    </summary>
    //                     <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
    //                     Building Bridges of Understanding
    //                     </h3>
    //                     <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
    //                     Empathy plays a crucial role in effective communication, ensuring that messages are not just heard but deeply understood.
    //                     </p>
    //                     <ul
    //                       className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
    //                       style={{ listStyle: "circle" }}
    //                     >
    //                       <li>
    //                       Practice active listening, giving your full attention to the speaker.
    //                       </li>
    //                       <li>
    //                       Use empathetic responses, such as "I can imagine how that must feel" or "That sounds challenging."
    //                       </li>
    //                       <li>
    //                       Reflect on your communication style. How can you infuse more empathy into your interactions?
    //                       </li>
    //                     </ul>
    //                   </details>

    //                   <details className="w-full border rounded-lg">
    //                     <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-primary font-medium">
    //                     Empathy in Action
    //                     </summary>
    //                     <h3 className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 font-medium mt-8">
    //                     Moving Beyond Feeling to Helping
    //                     </h3>
    //                     <p className="px-4 py-6 pt-0 ml-4 -mt-4 text-gray-600">
    //                     Empathy doesn't stop at understanding and feeling; it propels us to act, to support, and to help.
    //                     </p>
    //                     <ul
    //                       className="mt-6 px-4 py-6 pt-0 ml-4 -mt-4 text-gray-800 flex gap-4 flex-col"
    //                       style={{ listStyle: "circle" }}
    //                     >
    //                       <li>
    //                       Identify opportunities where you can offer support or assistance based on your empathetic understanding.
    //                       </li>
    //                       <li>
    //                       Engage in acts of kindness, big or small, driven by genuine empathy.
    //                       </li>
    //                       <li>
    //                       Reflect on times when someone's empathetic actions made a difference in your life. How can you pay it forward?
    //                       </li>
    //                     </ul>
    //                   </details> */}

    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>,

    // <section
    // className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
    // style={{ height: "100%" }}
    // >
    // {/* Step 1 content */}
    // <h2 className="text-xl font-medium sm:text-xl">
    //   <b>Empathy Mapping</b>
    // </h2>
    // <p className="mt-4 text-gray-600">
    // This activity aims to cultivate a deeper understanding of empathy by placing students in the shoes of a given character or scenario. Through the process of empathy mapping, students will explore the different dimensions of a character's experience, fostering a holistic understanding of their emotions, thoughts, and perceptions.
    // </p>

    // <ul
    //   className="mt-4 bg-amber-50 p-2 rounded-xl border-2 border-amber-100 text-sm"
    //   style={{ listStyle: "circle" }}
    // >
    //   <p className="mx-6 mt-4  font-medium flex flex-row gap-2 items-center bg-amber-400 text-white w-max py-1 px-3 rounded-full">
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       stroke-width="1.5"
    //       stroke="currentColor"
    //       className="w-6 h-6"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    //       />
    //     </svg>
    //     Instructions{" "}
    //   </p>
    //   <li className="m-6">
    //   Begin by reading the provided scenario about Ahmed. Immerse yourself in his story and consider the challenges and emotions he might be experiencing as a transfer student in a new university.
    //   </li>
    //   <li className="m-6">
    // Based on the scenario, determine the emotions Ahmed might be feeling. Is he anxious, excited, overwhelmed, hopeful, or perhaps a mix of several emotions?
    //   </li>
    //   <li className="m-6">
    // Identify the potential obstacles or challenges Ahmed might face. These could be feelings of isolation, navigating a new campus, or adjusting to a different academic environment.
    //   </li>
    //   <li className="m-6">
    // Consider the positive opportunities that this new environment might present for Ahmed. These could include making new friends, joining student organizations, or benefiting from diverse academic programs.
    //   </li>
    //   <li className="m-6">
    // Assess the external factors that might impact Ahmed's experience. This could involve the university's culture, the attitudes of other students, or the support systems available to him.
    //   </li>
    // </ul>

    // {status === "Completed" || disableBtn ? (
    //               <div className="my-4">
    //                 <p className="py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">

    //                   <ul>
    //                     <li><b>Thinking:</b><br/> {empathyMapping?.think}<br/><br/></li>
    //                     <li><b>Feeling:</b><br/> {empathyMapping?.feel}<br/><br/></li>
    //                     <li><b>Hearing:</b><br/> {empathyMapping?.hear}<br/><br/></li>
    //                     <li><b>Seeing:</b><br/> {empathyMapping?.see}<br/><br/></li>
    //                     <li><b>Saying:</b><br/> {empathyMapping?.say}<br/><br/></li>
    //                   </ul>

    //                 </p>
    //               </div>
    //             ) : (

    // <div>

    // <div className="mt-6 flex justify-start w-full">
    //   <div className="form-control w-full ">
    //     <label className="label">
    //       <span className="label-text font-medium"><b>Thinking: <br/><br/></b>What is on Ahmed's mind, and what are his thoughts, priorities, and concerns in the current situation?</span>
    //     </label>
    //     <textarea
    //       value={empathyMapping.think ?? ""}
    //       onChange={(e) =>
    //         setempathyMapping((prev) => ({ ...prev, think: e.target.value }))
    //       }
    //       className="textarea textarea-sm textarea-bordered h-20"
    //       placeholder="Type here.."
    //     ></textarea>
    //      <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping.think)} / 50 words</small>
    //   </div>
    // </div>

    // <div className="mt-6 flex justify-start w-full">
    //   <div className="form-control w-full ">
    //   <label className="label">
    //       <span className="label-text font-medium"><b>Feeling: <br/><br/></b>What emotions is Ahmed currently experiencing, and how does he feel about the situation?</span>
    //     </label>
    //     <textarea
    //       value={empathyMapping?.feel ?? ""}
    //       onChange={(e) =>
    //         setempathyMapping((prev) => ({ ...prev, feel: e.target.value }))
    //       }
    //       className="textarea textarea-sm textarea-bordered h-20"
    //       placeholder="Type here..."
    //     ></textarea>
    //      <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.feel)} / 50 words</small>
    //   </div>
    // </div>

    // <div className="mt-6 flex justify-start w-full">
    //   <div className="form-control w-full ">
    //   <label className="label">
    //       <span className="label-text font-medium"><b>Seeing: <br/><br/></b>What does Ahmed observe in his surroundings, and what aspects of this new environment stand out to him?</span>
    //     </label>
    //     <textarea
    //       value={empathyMapping?.see ?? ""}
    //       onChange={(e) =>
    //         setempathyMapping((prev) => ({
    //           ...prev,
    //           see: e.target.value,
    //         }))
    //       }
    //       className="textarea textarea-sm textarea-bordered h-20"
    //       placeholder="Type here..."
    //     ></textarea>
    //      <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.see)} / 50 words</small>
    //   </div>
    // </div>

    // <div className="mt-6 flex justify-start w-full">
    //   <div className="form-control w-full ">
    //   <label className="label">
    //       <span className="label-text font-medium"><b>Hearing: <br/><br/></b>What is Ahmed hearing, both directly and indirectly?</span>
    //     </label>
    //     <textarea
    //       value={empathyMapping.hear ?? ""}
    //       onChange={(e) =>
    //         setempathyMapping((prev) => ({ ...prev, hear: e.target.value }))
    //       }
    //       className="textarea textarea-sm textarea-bordered h-20"
    //       placeholder="Type here..."
    //     ></textarea>
    //      <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.hear)} / 50 words</small>
    //   </div>
    // </div>

    // <div className="mt-6 flex justify-start w-full">
    //   <div className="form-control w-full ">
    //   <label className="label">
    //       <span className="label-text font-medium"><b>Saying: <br/><br/></b>What verbal expressions or statements might Ahmed be making? What could he be communicating to others verbally?</span>
    //     </label>
    //     <textarea
    //       value={empathyMapping.say ?? ""}
    //       onChange={(e) =>
    //         setempathyMapping((prev) => ({ ...prev, say: e.target.value }))
    //       }
    //       className="textarea textarea-sm textarea-bordered h-20"
    //       placeholder="Type here..."
    //     ></textarea>
    //      <small className="mt-6 text-end text-gray-400"> {countWords(empathyMapping?.say)} / 50 words</small>
    //   </div>
    // </div>

    // <div className="flex items-center gap-2">
    //         <input
    //             type="checkbox"
    //             checked={checkBox}
    //             className="checkbox checkbox-warning"
    //             onChange={() => {
    //               setCheckBox(!checkBox); // Toggle the checkbox state
    //             }}
    //         />
    //         <span className="text-black-400 ">Do you agree to share your response with a peer for the purpose of receiving constructive feedback. </span>
    //       </div>

    //       <div className="flex items-center gap-2 mt-4 p-4 rounded-xl">
    //       <input
    //   type="checkbox"
    //   className="checkbox checkbox-warning "
    //   checked={RecheckBox}
    //   onChange={() => {
    //     setRecheckBox(!RecheckBox); // Toggle the checkbox state
    //   }}
    // />
    //         <span className="text-black-400 ">Read your submission carefully before submitting</span>
    //       </div>

    // <button className="btn btn-primary mt-8 bg-neutral-50" onClick={handleStepSubmission}
    // disabled={status === 'Completed' || !RecheckBox ||  !isEveryFieldValid || !checkBox || disableBtn }
    // >
    //   Submit
    // </button>
    // </div>
    //             )}

    // </section>,

    //     <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
    //       {/* Step 1 content */}
    //       <Confetti numberOfPieces={100} />
    //       <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    //         <div className="grid grid-cols-1 gap-8 lg:grid-cols-1 lg:gap-16">

    //           <div className="">
    //             <h2 className="text-xl font-medium sm:text-xl">
    //               <b>Congratulations on Completing Week 4: Empathy - The Heart of Leadership!</b>
    //             </h2>
    //             <p className="mt-4 text-gray-600">
    //             As we conclude our exploration into the essence of empathy, we recognize the transformative power it holds in leadership. This week, we've delved deep into understanding, feeling, and acting with empathy, using it as a tool to forge genuine connections and lead with authenticity.
    //             </p>

    //             <div className="mt-6 ">
    //               <div className="space-y-4">
    //               <Collapse accordionData={accordionData3}/>
    //               </div>

    //             </div>

    //             <p className="mt-4 text-gray-600">
    //             Thank you for your dedication, engagement, and commitment to growth. Let's continue our ascent towards leadership excellence!
    // <br/><br/>
    // Feel free to explore the following resources to help you understand more about yourself.            </p>

    //             <ul class="flex flex-col" style={{listStyle:"disc"}}>
    //   <li class="flex flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //   <strong>How to Win Friends and Influence People</strong> by Dale Carnegie, Chapter 8 – A Formula That Will Work Wonders for You
    //   </li>
    //   <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //   <strong>The Art of Empathy</strong> by Karla McLaren, Chapter 2 – Six Habits of Highly Empathic People
    //   </li>

    //   <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //   <strong>Mindwise</strong> by Nicholas Epley, Chapter 5 – Understanding Other People’s Minds  </li>

    //   <li class="flex  flex-col text-start justify-start items-start gap-x-2 py-3 px-4 font-medium bg-white border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-white">
    //   <strong>The Power of Empathy</strong> by Helen Riess
    //   </li>
    // </ul>

    //           </div>
    //         </div>
    //       </div>
    //     </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Setting Clear Expectations and Accountability";

      case 2:
        return "Scenario Based MCQs";

      case 3:
        return "Addressing Underperformance in Teams";

      case 4:
        return "True/False Questions";

      case 5:
        return "Self-Assessment";
      case 6:
        return "Reflection Questions";

      case 7:
        return "Case Study - Motivation and Recognition"
      default:
        return "Reflection Questions";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary flex flex-col md:flex-row shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              disabled={status === "Completed" ? false : (currentStep === 5 && (!swotSubmission.response || countWords(swotSubmission.response) < 10 || !isSubmitted)) || ([2,4,6].includes(currentStep) && !isOptionSelected)}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={currentStep === 8 && !isOptionSelected}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week5;
