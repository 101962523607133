import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import Select from "react-select";

function Form({ inputRef }) {
  const [cgpa, setCgpa] = useState(2.0);

  const handleCgpaChange = (event) => {
    setCgpa(event.target.value);
  };

  const [isShownSOC, setIsShownSOC] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prologue, setprologue] = useState(false);
  const handleClickSOC = (event) => {
    // 👇️ toggle shown state
    setIsShownSOC((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const [isShownCON, setIsShownCON] = useState(false);

  const handleClickCON = (event) => {
    // 👇️ toggle shown state
    setIsShownCON((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const [isShownEND, setIsShownEND] = useState(false);

  const handleClickEND = (event) => {
    // 👇️ toggle shown state
    setIsShownEND((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [year, setYear] = useState();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [roll, setRoll] = useState("");
  const [societies, setsocieties] = useState([]);
  const [position, setPosition] = useState([]);
  const [promotion, setPromotion] = useState("");
  const [isValid, setIsValid] = useState(true);

  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const PositionOptions = [
    { value: "LES", label: "LES" },
    { value: "SPADES", label: "SPADES" },
    { value: "AHANG", label: "AHANG" },
    { value: "LCG", label: "LCG" },
    // Add other options as needed
  ];

  const handlePositionSelect = (options) => {
    setPosition(options);
  };

  const [societiesList, setSocietiesList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);

  const addArr = () => {
    setSocietiesList([...societiesList, ""]);
    setPositionsList([...positionsList, ""]);
  };

  const removeArr = (index, event) => {
    event.preventDefault();

    const updatedSocieties = [...societiesList];
    const updatedPositions = [...positionsList];

    updatedSocieties.splice(index, 1);
    updatedPositions.splice(index, 1);

    setSocietiesList(updatedSocieties);
    setPositionsList(updatedPositions);
  };

  // const validateInput = () => {
  //   const rollNumberPattern = /^\d+$/;

  //   const emailPattern = /^(\d*)@lums\.edu\.pk$/;

  //   const emailMatch = email.match(emailPattern);
  //   const isRollNumberValid = rollNumberPattern.test(roll);

  //   if (emailMatch && isRollNumberValid) {
  //     const emailRollNumber = emailMatch[1];
  //     setIsValid(emailRollNumber === roll);
  //   } else {
  //     setIsValid(false);
  //   }
  // };


  const validateInput = () => {
    const rollNumberPattern = /^\d{8}$/; // Updated regex to ensure exactly 8 digits

    const emailPattern = /^(\d{8})@lums\.edu\.pk$/; // Updated regex to ensure exactly 8 digits before @

    const emailMatch = email.match(emailPattern);
    const isRollNumberValid = rollNumberPattern.test(roll);

    if (emailMatch && isRollNumberValid) {
      const emailRollNumber = emailMatch[1];
      setIsValid(emailRollNumber === roll);
    } else {
      setIsValid(false);
    }
  };


  const validateLumsEmail = (email) => {
    return email.toLowerCase().endsWith("@lums.edu.pk");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateLumsEmail(email)) {
      toast.error("Please enter a valid LUMS email address (ending with @lums.edu.pk)", {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_KEY}/api/register_student/`, {
        email: email,
        student_school: 1,
        firstname: firstname,
        lastname: lastname,
        role: "Student",
        contact: "123",
        semester: year,
        school: selectedSchool?.value,
        cgpa: cgpa,
        roll: roll,
        societies: societiesList,
        position: positionsList,
        promotion: promotion,
      });

      if (response.status === 200) {
        toast.success("Registration successful!", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        console.log("Registration successful!");
      }
    } catch (error) {
      let errorMessage = "Registration failed!";
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.response?.data) {
        errorMessage = JSON.stringify(error.response.data);
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      toast.error(errorMessage, {
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      console.error("Error occurred:", error);
      setIsSubmitting(false); // Reset on error
      return; // Don't proceed to success state on error
    }
    setIsSubmitting(true); // Keep true on success to show success message
  };

  useEffect(() => {
    if (roll || email) {
      validateInput();
    }
  }, [roll, email]);


  const allSocietiesList = [
    'Aiesec Society',
    'Amnesty International',
    'Dramaline Society',
    'Debating and Recitation Society',
    'IEEE LUMS Student Chapter',
    'LUMS Adventure Society',
    'Law and Politics Society',
    'LUMS Entrepreneurship Society',
    'LUMS Literary Society',
    'LUMS Media Arts Society',
    'LUMS Art Club',
    'LUMS Culture Society',
    'LUMS Religious Society',
    'LUMS Model United Nations',
    'LUMS Environmental Action Forum',
    'LUMS Community Service Society',
    'LUMS Daily Student Society',
    'Lums Photographic Society',
    'Lums Students Maths Society',
    'Lums Music Society',
    'Publication at LUMS',
    'The Economics Society',
    'Sharing Analysis on Regional Ec',
    'SPADES',
    'FINTRA - Finance',
    'Feministy Society',
    'Lums Consultancy Group',
    'Psychology Society at LUMS',
    'LUMS Student Professional Accountancy',
    'Index the Design Society',
    'LUMS Culinary Society',
    'Hum Aahang Society',
    'LUMS Society of Chemical Sciences & Engineering',
    'Animals and Welfare Society',
    'Rizq',
    'Better Educational Engagement (BEE)',
    'LUMS Data Science Society (LDSS)',
    'Riayat LUMS Chapter (Riayat)',
    'LUMS Dance Society',
    'Radio at LUMS',
    'LUMS Women in Computing',
    'Anime and Manga at LUMS',
    'LUMS Historical Research Society',
    'SCOLAR Lahore HUB',
    'LUMS Policy Research Initiative',
    'Toastmaster',
    'Escapade (Graduate Society)',
    'E Gaming (Graduate Society)',
    'Education and Professional Excellence (Graduate Society)',
    'Entertainment (Graduate Society)',
    'Women in Business (Graduate Society)',
  ];

  const sortedOptions = allSocietiesList.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));


  const [selectedSchool, setSelectedSchool] = useState(null);
  const [options, setOptions] = useState([
    { value: 'SAHSL', label: 'Shaikh Ahmad Hassan School of Law' },
    { value: 'SDSB', label: 'Suleman Dawood School of Business' },
    { value: 'MGHHS', label: 'Mushtaq Ahmad Gurmani School of Humanities and Social Sciences' },
    { value: 'SOE', label: 'Syed Ahsan Ali and Syed Maratib Ali School of Education' },
    { value: 'SSE', label: 'Syed Babar Ali School of Science and Engineering' },
  ]);

  const handleInputChange = (inputValue) => {
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleChange = (selectedOption) => {
    setSelectedSchool(selectedOption);
  };


  return (
    <div>



{!isSubmitting ? (
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto mt-12 ">
        {/* Grid */}

        <div className="grid md:grid-cols-2 items-start gap-12">
          <div>
            <button
              type="button"
              class="relative mb-6 md:mb-4 btn items-center hover:bg-white btn-sm btn-error rounded-full"
            >
              Limited Seats Available
              <span class="flex   ">
                <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75 darki:bg-red-600"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500 "></span>
              </span>
            </button>

            <h1 className="text-3xl font-bold text-primary text-gray-800 sm:text-4xl lg:text-5xl lg:leading-tight darki:text-white">
              Apply for Ascent Leadership Program
            </h1>
            <p className="mt-1 md:text-lg text-gray-800 darki:text-gray-200">
             The Ascent Leadership Program is a 10-week leadership development course designed to prepare students for Executive Council (EC) positions in societies. Focused on essential leadership skills, the program enhances decision-making, communication, teamwork, and problem-solving through interactive learning and real-world case studies.
            </p>
            <div className="mt-8">
              <h2 className="text-lg font-semibold text-gray-800 darki:text-gray-200">
                What can I expect?
              </h2>
              <ul className="mt-2 space-y-2">
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 darki:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span className="text-gray-600 darki:text-gray-400">
                  Weekly Unlocks & Practical Learning – Interactive modules covering leadership essentials with society-specific case studies, and scenarios.
                    </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 darki:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span className="text-gray-600 darki:text-gray-400">
                  Skill Development & Leadership Training – Master communication, decision-making, teamwork, negotiation, and crisis management.
                    </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 darki:text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span className="text-gray-600 darki:text-gray-400">
Preparation for Executive Council Roles – Gain the knowledge and confidence required to take on leadership responsibilities.
                  </span>
                </li>
                
                </ul>
                {/* End Avatar Group */}
                <span className="text-sm text-gray-500">
                  Enroll today. Few seats left
                </span>
              </div>
            </div>
            {/* End Col */}
            <div className="relative">
              {/* Card */}
              <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-10 ">
                <h2 className="text-xl font-semibold text-gray-800 darki:text-gray-200">
                  Fill in the enrollment form
                </h2>
                <form>
                  {!isValid && (
                    <p className="text-red">
                      {roll && email ? (
                        <div class="bg-red-50 border border-red-200 text-sm text-red-800 rounded-lg p-4 darki:bg-red-800/10 darki:border-red-900 darki:text-red-500" role="alert">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <svg class="flex-shrink-0 h-4 w-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
                            </div>
                            <div class="ms-4">
                              <h3 class="text-sm text-red-700 font-semibold">
                                Email and Roll # instructions
                              </h3>
                              <div class="mt-2 text-sm text-red-700 darki:text-red-400">
                                <ul class="list-disc space-y-1 ps-5">
                                  <li>
                                    Email should end with <span className="font-medium">@lums.edu.pk </span>
                                  </li>
                                  <li>
                                    Use LUMS roll number - <span className="font-medium">8 digits</span>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="bg-red-50 border border-red-200 text-sm text-red-800 rounded-lg p-4 darki:bg-red-800/10 darki:border-red-900 darki:text-red-500" role="alert">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <svg class="flex-shrink-0 h-4 w-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
                            </div>
                            <div class="ms-4">
                              <h3 class="text-sm text-red-700 font-semibold">
                                Email and Roll # instructions
                              </h3>
                              <div class="mt-2 text-sm text-red-700 darki:text-red-400">
                                <ul class="list-disc space-y-1 ps-5">
                                  <li>
                                    Email should end with <span className="font-medium">@lums.edu.pk </span>
                                  </li>
                                  <li>
                                    Use LUMS roll number - <span className="font-medium">8 digits</span>
                                  </li>

                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </p>
                  )}
                  <div className="mt-6 grid gap-4 lg:gap-6">
                    {/* Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          htmlFor="hs-firstname-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          First Name
                        </label>
                        <input
                          required
                          type="text"
                          name="hs-firstname-hire-us-1"
                          id="hs-firstname-hire-us-1"
                          placeholder="John"
                          className="input input-bordered w-full"
                          onChange={(e) => setFirstname(e.target.value)}
                          ref={inputRef}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="hs-lastname-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          Last Name
                        </label>
                        <input
                          required
                          type="text"
                          name="hs-lastname-hire-us-1"
                          id="hs-lastname-hire-us-1"
                          placeholder="Doe"
                          className="input input-bordered w-full"
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* End Grid */}

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          htmlFor="hs-work-email-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          LUMS Email
                        </label>

                        <input
                          required
                          type="email"
                          name="hs-work-email-hire-us-1"
                          id="hs-work-email-hire-us-1"
                          autoComplete="email"
                          placeholder="12345678@lums.edu.pk"
                          className={`input input-bordered w-full ${isValid ? "" : "border-2 border-red-500 "
                            }`}
                          // onChange={(e) => {
                          //   setEmail(e.target.value)
                          //   setIsValid(true);
                          // }}

                          onBlur={validateInput} // Change here: use onBlur instead of onChange
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setIsValid(true);
                          }}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="hs-company-website-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          Semester
                        </label>
                        <select
                          className="select select-bordered w-full max-w-xs"
                          onChange={(e) => setYear(e.target.value)}
                        >
                          <option disabled selected>
                            Select one
                          </option>
                          <option value="1">Semester 1</option>
                          <option value="2">Semester 2</option>
                          <option value="3">Semester 3</option>
                          <option value="4">Semester 4</option>
                          <option value="5">Semester 5</option>
                          <option value="6">Semester 6</option>
                          <option value="7">Semester 7</option>
                          <option value="8">Semester 8</option>
                        </select>
                      </div>
                    </div>
                    {/* Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          htmlFor="hs-company-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          Roll Number
                        </label>
                        <input
                          required
                          type="text"
                          name="hs-company-hire-us-1"
                          id="hs-company-hire-us-1"
                          placeholder="12345678"
                          className={`input input-bordered w-full ${isValid ? "" : "border-2 border-red-500 "
                            }`}
                          // onChange={(e) => {
                          //   setRoll(e.target.value)
                          //   setIsValid(true);
                          // }}
                          onBlur={validateInput} // Change here: use onBlur instead of onChange
                          onChange={(e) => {
                            setRoll(e.target.value);
                            setIsValid(true);
                          }}
                        />
                      </div>
                      {/* <div>
                      <label
                        htmlFor="hs-company-website-hire-us-1"
                        className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                      >
                        School
                      </label>
                      <select
                        className="select select-bordered w-full max-w-xs"
                        onChange={(e) => setSchool(e.target.value)}
                      >
                        <option disabled selected>
                          Select one
                        </option>
                        <option value="SAHSL">Shaikh Ahmad Hassan School of Law</option>
                        <option value="SDSB">Suleman Dawood School of Business</option>
                        <option value="MGHHS">Mushtaq Ahmad Gurmani School of Humanities and Social Sciences</option>
                        <option value="SOE">Syed Ahsan Ali and Syed Maratib Ali School of Education</option>
                        <option value="SSE">Syed Babar Ali School of Science and Engineering</option>
                      </select>
                    </div> */}


                      <div>
                        <label
                          htmlFor="hs-company-website-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          School
                        </label>
                        <Select
                          value={selectedSchool}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          options={options}
                          isSearchable
                          className="react-select-container "
                          classNamePrefix="react-select "
                          placeholder="eg: SDSB"
                        />
                      </div>

                    </div>

                    <div className="grid grid-cols-1 gap-4 lg:gap-6">
                      <div>
                        <label
                          htmlFor="hs-company-hire-us-1"
                          className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                        >
                          CGPA - <strong className="text-primary">{cgpa}</strong>
                        </label>
                        <input
                          required
                          type="range"
                          id="cgpa-slider"
                          name="cgpa"
                          min="2.0"
                          max="4.0"
                          step="0.1"
                          value={cgpa}
                          // onChange={handleCgpaChange}
                          onChange={(e) => {
                            handleCgpaChange(e);
                            setCgpa(e.target.value);
                          }}
                          className="range w-full"
                        />
                      </div>
                    </div>

                    <label className="flex items-center gap-4 space-x-2 border p-4 rounded-xl">
                      <input
                        type="checkbox"
                        checked={isShownSOC}
                        // onChange={handleClickSOC}
                        className="form-checkbox checkbox checkbox-primary h-5 w-5 text-indigo-600"
                        onChange={(e) => {
                          handleClickSOC();
                          setPromotion(e.target.value);
                        }}
                      />
                      <span className="text-gray-700">
                        Are you currently part of any society at LUMS?
                      </span>
                    </label>

                    {isShownSOC && (
                      <div className="flex flex-col gap-4">
                        {societiesList?.map((society, index) => (
                          // <div
                          //     key={index}
                          //     className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6"
                          // >
                          //   <div>
                          //     <label
                          //         htmlFor={`society-${index}`}
                          //         className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                          //     >
                          //       Societies
                          //     </label>
                          //     <select
                          //         className="select select-bordered w-full max-w-xs"
                          //         value={society}
                          //         onChange={(e) => {
                          //           const updatedSocieties = [...societiesList];
                          //           updatedSocieties[index] = e.target.value;
                          //           setSocietiesList(updatedSocieties);
                          //         }}
                          //     >
                          //       <option disabled value="">
                          //         Select a society
                          //       </option>
                          //       <option value="LES">LES</option>
                          //       <option value="SPADES">SPADES</option>
                          //       <option value="AHANG">AHANG</option>
                          //       <option value="LCG">LCG</option>
                          //     </select>
                          //   </div>
                          //   <div>
                          //     <label
                          //         htmlFor={`position-${index}`}
                          //         className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                          //     >
                          //       Position
                          //     </label>
                          //     <select
                          //         className="select select-bordered w-full max-w-xs"
                          //         value={positionsList[index]}
                          //         onChange={(e) => {
                          //           const updatedPositions = [...positionsList];
                          //           updatedPositions[index] = e.target.value;
                          //           setPositionsList(updatedPositions);
                          //         }}
                          //     >
                          //       <option disabled value="">
                          //         Select EC position
                          //       </option>
                          //       <option value="President">President</option>
                          //       <option value="VP">VP</option>
                          //       <option value="Treasurer">Treasurer</option>
                          //       <option value="GeneralSecretary">
                          //         General Secretary
                          //       </option>
                          //       <option value="Director">Director</option>
                          //     </select>
                          //   </div>
                          //   <div className="mt-4 flex flex-row gap-2 justify-start">
                          //     <button
                          //         className="btn btn-outlined btn-sm font-bold"
                          //         onClick={(e) => removeArr(index, e)}
                          //     >
                          //       - Remove
                          //     </button>
                          //   </div>
                          // </div>

                          <div className="w-full flex items-center justify-between p-6 border  rounded-xl ">
                            <div className="w-2/5">
                              <label
                                htmlFor={`society-${index}`}
                                className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                              >
                                Societies
                              </label>
                              <select
                                className="select select-bordered w-full max-w-xs"
                                value={society}
                                onChange={(e) => {
                                  const updatedSocieties = [...societiesList];
                                  updatedSocieties[index] = e.target.value;
                                  setSocietiesList(updatedSocieties);
                                }}
                              >
                                <option disabled value="">
                                  Select a society
                                </option>
                                {sortedOptions.map((allSocietiesList, index) => (
                                  <option key={index} value={allSocietiesList}>
                                    {allSocietiesList}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="w-2/5">
                              <label
                                htmlFor={`position-${index}`}
                                className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                              >
                                Position
                              </label>
                              <select
                                className="select select-bordered w-full max-w-xs"
                                value={positionsList[index]}
                                onChange={(e) => {
                                  const updatedPositions = [...positionsList];
                                  updatedPositions[index] = e.target.value;
                                  setPositionsList(updatedPositions);
                                }}
                              >
                                <option disabled value="">
                                  Select EC position
                                </option>
                                <option value="President">President</option>
                                <option value="VP">VP</option>
                                <option value="Treasurer">Treasurer</option>
                                <option value="GeneralSecretary">
                                  General Secretary
                                </option>
                                <option value="Director">Director</option>
                                <option value="Member">Member</option>
                              </select>
                            </div>

                            <div
                              onClick={(e) => removeArr(index, e)}
                              className="bg-[#FEE2E2] w-12 rounded-xl mt-7 p-2 flex items-center justify-center cursor-pointer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" data-slot="icon" class="w-5 text-red h-5">
                                <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
                              </svg>

                            </div>
                          </div>
                        ))}
                        <div>
                          <button
                            type="button"
                            style={{ background: "#2563EB" }}
                            className=" text-white text-sm   font-normal p-3 rounded-xl"
                            onClick={() => addArr()}
                          >
                            Add More +
                          </button>
                        </div>
                      </div>
                    )}

                    <label className="flex items-center gap-4 space-x-2 border p-4 rounded-xl">
                      <input
                        type="checkbox"
                        checked={isShownCON}
                        onChange={handleClickCON}
                        className="form-checkbox checkbox checkbox-primary h-5 w-5 text-indigo-600"
                      />
                      <span className="text-gray-700">
                        Are you currently pursuing any student counsel position at
                        LUMS?
                      </span>
                    </label>

                    {isShownCON && (
                      <div className="grid grid-cols-1 ">
                        <div>
                          <label
                            htmlFor="hs-company-website-hire-us-1"
                            className="block mb-2 text-sm text-gray-700 font-medium darki:text-white"
                          >
                            Counsel position
                          </label>
                          <select className="select select-bordered w-full ">
                            <option disabled selected>
                              Select Counsel position
                            </option>
                            <option>President</option>
                            <option>VP</option>
                            <option>Treasurer</option>
                            <option>General Secretary</option>
                            <option>Director</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* End Grid */}
                  {/* Checkbox */}

                  {/* End Checkbox */}
                  <div className="mt-6 grid">
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      // disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Send Application
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-5 h-5"
                      >
                        <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
                      </svg>
                    </button>
                  </div>
                </form>
                <div className="mt-3 text-center">
                  <p className="text-sm text-gray-500">
                    You'll get a confirmation email once your application has been
                    approved
                  </p>
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Col */}
          </div>
          {/* End Grid */}
        </div>
      ) : (
        <div>
          <div class="min-h-[15rem] bg-white w-8/12 mx-auto flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
            <div class="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
              <img className="w-64" src="https://i.pinimg.com/originals/37/78/db/3778dbeebfe43a60eca57c5aecfdcf0d.gif" alt="" loop={false} />
              <h3 class="text-lg font-bold text-gray-800 darki:text-white">
                Application has been received!
              </h3>
              <p class="mt-2 text-gray-500 darki:text-gray-400">
                You'll get a confirmation email once your application has been approved
              </p>
            </div>
          </div>
        </div>
      )}
      <>






      </></div>
  );
}

export default Form;
